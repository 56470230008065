import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { StartTestPageTestProtocol } from './pages/StartTestPageTestProtocol';
import { TestPageTestProtocol } from './pages/TestPageTestProtocol';
import { ResultPageTestProtocol } from "./pages/ResultPageTestProtocol";
import { TestPageTestPrevention } from "./pages/TestPageTestPrevention";
import { StartTestPageTestPrevention } from "./pages/StartTestPageTestPrevention";
import { ResultPageTestPrevention } from "./pages/ResultPageTestPrevention";
import { StartTestPageTestSafety } from "./pages/StartTestPageTestSafety";
import { TestPageTestSafety } from "./pages/TestPageTestSafety";
import { ResultPageTestSafety } from "./pages/ResultPageTestSafety";

function App() {
  return (
    <BrowserRouter>
      <div className="container pt-4">
        <Routes>
          <Route path="/test_protocol/" exact element={<StartTestPageTestProtocol />}/>
          <Route path="/test_protocol/test" element={<TestPageTestProtocol />}/>
          <Route path="/test_protocol/result" element={<ResultPageTestProtocol />}/>
          <Route path="/test_prevention/" exact element={<StartTestPageTestPrevention />}/>
          <Route path="/test_prevention/test" element={<TestPageTestPrevention />}/>
          <Route path="/test_prevention/result" element={<ResultPageTestPrevention />}/>
          <Route path="/test_safety/" exact element={<StartTestPageTestSafety />}/>
          <Route path="/test_safety/test" element={<TestPageTestSafety />}/>
          <Route path="/test_safety/result" element={<ResultPageTestSafety />}/>
          <Route path = '*' element={<Navigate replace to='/test_protocol/' />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}
 
export default App;
