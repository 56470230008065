import React, { useEffect, useState } from "react";
import { Questions } from "../data/QuestionsTestPrevention";
import { Shuffle } from "../methods/Shuffle";
import { Radio, RadioGroup, FormControlLabel, FormControl, IconButton, Snackbar, Checkbox, Divider } from "@mui/material";
import { LinearProgressWithLabel } from "../components/LinearProgressWithLabel";
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const roleUser = JSON.parse(localStorage.getItem('roleTest'));

const arrIdTests = [];
for (let i = 0; i < Questions.length; i++) {
    if (Questions[i].roleTest === roleUser) {
        arrIdTests.push(Questions[i].id);
    } else if (Questions[i].roleTest === "DOCTOR_ROLE" && roleUser === "SURGEON_DOC_ROLE") {
        arrIdTests.push(Questions[i].id);
    }
}

Shuffle(arrIdTests);

let arrIdTestsByTopic = [];
let countTestByTopic = [0, 0, 0];
const numberTestByTopic = 8;

for (let i = 0; i < arrIdTests.length; i++) {
    if (Questions[arrIdTests[i]].topic === 'Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений'
        && countTestByTopic[0] < numberTestByTopic) {
        arrIdTestsByTopic.push(arrIdTests[i]);
        countTestByTopic[0] += 1;
    } else if (Questions[arrIdTests[i]].topic === 'Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей'
        && countTestByTopic[1] < numberTestByTopic) {
        arrIdTestsByTopic.push(arrIdTests[i]);
        countTestByTopic[1] += 1;
    } else if (Questions[arrIdTests[i]].topic === 'Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока'
        && countTestByTopic[2] < numberTestByTopic) {
        arrIdTestsByTopic.push(arrIdTests[i]);
        countTestByTopic[2] += 1;
    }
}

let answerTheQuestons = [];
let checkedAnswer = [];

export const TestPageTestPrevention = () => {
    const [testId, setTestId] = useState(arrIdTestsByTopic[0]);

    const [open, setOpen] = React.useState(false);
    const [responce, setResponce] = useState(-1);
    const [countPage, setCountPage] = useState(0);
    const [active, setActive] = useState(true);

    const nextQuestion = () => {
        setActive(false);
    }

    useEffect(() => { setActive(true) }, [testId])

    if (!Questions[testId].isSingleResponce && checkedAnswer.length === 0) {
        Questions[testId].responseOptions.map((res) =>
            checkedAnswer.push({
                id: res.id,
                check: false
            })
        )
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleChangeRadioGroup = (event) => {
        setResponce(event.target.value);
    }

    const action = (
        <Alert severity="error">
            This is an error alert — check it out!
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </Alert>
    );

    const handleChangeCheckBox = (event) => {
        checkedAnswer[event.target.value].check = event.target.checked;
    }

    const handleChangeBtn = () => {
        if (Questions[testId].isSingleResponce) {
            if (responce === -1) {
                setOpen(true);
                return;
            }

            answerTheQuestons.push({
                idQuestion: Questions[testId].id,
                responceClient: responce,
            });
        } else {
            let responceCheck = checkedAnswer.filter(res => res.check === true)

            if (responce === -1 && responceCheck.length === 0) {
                setOpen(true);
                return;
            }

            let arrayAnswer = [];
            responceCheck.map((res) =>
                arrayAnswer.push(res.id)
            );

            answerTheQuestons.push({
                idQuestion: Questions[testId].id,
                responceClient: arrayAnswer,
            })
        }

        setResponce(-1);
        checkedAnswer.length = 0;

        if (countPage < arrIdTestsByTopic.length - 1) {
            setTestId(arrIdTestsByTopic[countPage + 1]);
            setCountPage(countPage + 1);
            nextQuestion();
        } else {
            let serialObj = JSON.stringify(answerTheQuestons); //сериализуем его
            localStorage.setItem("answerClient", serialObj);
            let serialObject = JSON.stringify(arrIdTestsByTopic); //сериализуем его
            localStorage.setItem("arrIdTests", serialObject);
            window.location.assign('/test_prevention/result');
        }
    }

    return (
        <div className="container">
            <div className="row text-center">
                <div className="col-2">
                    <p>{countPage + '/' + arrIdTestsByTopic.length}</p>
                </div>
                <div className="col-10">
                    <LinearProgressWithLabel value={countPage * (100 / arrIdTestsByTopic.length)} />
                </div>
                <Divider sx={{borderBottomWidth: 3, marginBottom: 2}}/>
            </div>
            <FormControl>
                <h1>{Questions[testId].question}</h1>
                {active && !Questions[testId].isSingleResponce &&
                    Questions[testId].responseOptions.map((res) => <FormControlLabel value={res.id}
                        control={<Checkbox style={{ color: '#058877' }} checked={checkedAnswer[res.id].checked} onChange={handleChangeCheckBox} />}
                        label={res.answer} />)}

                {active && Questions[testId].isSingleResponce &&
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        value={responce}
                        onChange={handleChangeRadioGroup}
                    >
                        {Questions[testId].responseOptions.map((res) => <FormControlLabel value={res.id} control={<Radio style={{ color: '#058877' }} />} label={res.answer} />)}
                    </RadioGroup>}

            </FormControl>

            <div className="row">
                <button type="button" style={{ backgroundColor: "#058877", color: "white", marginTop: '8pt' }} className="btn btn-light" onClick={handleChangeBtn}>Следующий вопрос!</button>
            </div>
            <div>
                <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    action={action}
                >
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        Необходимо выбрать ответ на вопрос!
                    </Alert>
                </Snackbar>
            </div>
        </div>
    );
};