import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

export const StartTestPageTestSafety = () => {

  useEffect(() => { document.title = "Система тестирования компетенций врачебного коллектива многопрофильного стационара по вопросам безопасности пациентов при оказании специализированной медицинской помощи"; }, []);

  const [role, setRole] = useState("NURSE_ROLE");

  const handleChangeRole = (event) => {
    setRole(event.target.value);
  }

  const handleChange = () => {
    let serialObj = JSON.stringify(role); //сериализуем его
    localStorage.setItem("roleTest", serialObj);
    window.location.assign("/test_safety/test");
  };

  return (
    <Grid container direction="column" alignItems="center" spacing={3}>
      <Grid item marginTop="12pt" />
      <Grid item direction="row">
        <Typography align="center" fontSize="34pt" mt="4">
          Система тестирования компетенций врачебного коллектива многопрофильного стационара по вопросам безопасности пациентов при оказании специализированной медицинской помощи
        </Typography>
      </Grid>

      <Grid item direction="row">
        <Typography align="center">
          Цель тестовых заданий – представить основные вопросы безопасности пациентов при оказании специализированной медицинской помощи в 
          медицинской организации в части стратегии обеспечения безопасности пациентов и управления клиническими рисками, профилактики падений 
          пациентов и профилактики пролежней. Содержание тестовых заданий соответствует основным профессиональным образовательным программам 
          высшего образования – подготовке кадров высшей квалификации в ординатуре и дополнительной профессиональной программе профессиональной 
          переподготовки врачей по специальностям «Организация здравоохранения и общественное здоровье», «Акушерство и гинекология». «Анестезиология и 
          реаниматология», «Гастроэнтерология», «Пульмонология», «Ревматология», «Эндокринология», «Терапия», «Общая врачебная практика (семейная медицина)», 
          «Травматология и ортопедия», «Хирургия», «Гинекология», «Анестезиология и реаниматология», «Аллергология и иммунология», «Гематология», «Нефрология», 
          «Урология», «Гериатрия», «Педиатрия», «Кардиология», «Инфекционные болезни», «Клиническая лабораторная диагностика», «Неонатология», «Клиническая фармакология», 
          «Косметология», «Колопроктология», «Неврология», «Оториноларингология», «Офтальмология», «Психиатрия и наркология», «Профессиональная патология», 
          «Скорая медицинская помощь», «Сердечно-сосудистая хирургия», «Стоматология», «Торакальная хирургия», «Трансфузиология», «Токсикология», 
          «Ультразвуковая диагностика», «Фтизиатрия», «Эндоскопия», «Эпидемиология», «Физиотерапия», «Функциональная диагностика», «Челюстно-лицевая хирургия», 
          «Терапевтическая стоматология», «Хирургическая стоматология», «Ортодонтия», «Клиническая фармакология», а также дополнительных профессиональных 
          программ повышения квалификации и профессиональной переподготовки специалистов со средним медицинским образованием по специальностям: «Сестринское дело», 
          «Сестринское дело в хирургии», «Сестринское дело в терапии». Тесты предназначены для руководителей медицинских организаций, специалистов по организации 
          здравоохранения, практикующих врачей и среднего медицинского персонала.
        </Typography>
      </Grid>


      <Grid marginTop="12pt" justifyContent="center">
        <FormControl>
          <Typography align="center" style={{ fontSize: 20 }}>Выберите роль, тест которой хотите пройти</Typography>
          <Grid container justifyContent="center">
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="NURSE_ROLE"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="NURSE_ROLE"
                control={<Radio />}
                label="Медсестра"
                onChange={handleChangeRole}
              />
              <FormControlLabel value="DOCTOR_ROLE" control={<Radio />} label="Доктор" onChange={handleChangeRole} />
            </RadioGroup>
          </Grid>
        </FormControl>
      </Grid>

      <Grid container justifyContent="center" direction="row" marginTop="8pt">
        <button
          type="button"
          className="btn btn-light"
          style={{ backgroundColor: "#058877", color: "white" }}
          onClick={handleChange}
        >
          Начать тестирование!
        </button>
      </Grid>

    </Grid>
  );
};
