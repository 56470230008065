import React, { useState } from "react";
import { Questions } from "../data/QuestionsTestPrevention";
import { options } from "../components/RadarConfig";
import { Radar } from 'react-chartjs-2';
import { isEqual } from "../methods/IsEqual";
import Divider from '@mui/material/Divider';

import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
);

const answerTheQuestons = JSON.parse(localStorage.getItem("answerClient"));
const arrIdTest = JSON.parse(localStorage.getItem('arrIdTests'));

export const ResultPageTestPrevention = () => {

    const rightResponceTestByTopic = [0, 0, 0];
    const falseResponceTestByTopic = [0, 0, 0];
    const idFalseResponceTest = [];

    const handleChange = () => {
        localStorage.removeItem("answerClient");
        localStorage.removeItem('arrIdTest');
        window.location.assign('/test_prevention/');
    }

    const countRightResponceTest = () => {
        let rightRes = 0;

        for (let i = 0; i < arrIdTest.length; i++) {
            if (Questions[arrIdTest[i]].isSingleResponce) {
                if (Questions[arrIdTest[i]].idCorrectAnswer[0] === Number(answerTheQuestons[i].responceClient)) {
                    rightRes += 1;
                    if (Questions[arrIdTest[i]].topic === "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений") {
                        rightResponceTestByTopic[0] += 1;
                    } else if (Questions[arrIdTest[i]].topic === "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей") {
                        rightResponceTestByTopic[1] += 1;
                    } else if (Questions[arrIdTest[i]].topic === "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока") {
                        rightResponceTestByTopic[2] += 1;
                    }
                }
            } else {
                if (isEqual(Questions[arrIdTest[i]].idCorrectAnswer, answerTheQuestons[i].responceClient)) {
                    rightRes += 1;
                    if (Questions[arrIdTest[i]].topic === "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений") {
                        rightResponceTestByTopic[0] += 1;
                    } else if (Questions[arrIdTest[i]].topic === "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей") {
                        rightResponceTestByTopic[1] += 1;
                    } else if (Questions[arrIdTest[i]].topic === "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока") {
                        rightResponceTestByTopic[2] += 1;
                    }
                }
            }
        }
        return (rightRes);
    };

    const countFalseResponceTest = () => {
        let falseRes = 0;

        for (let i = 0; i < arrIdTest.length; i++) {
            if (Questions[arrIdTest[i]].isSingleResponce) {
                if (Questions[arrIdTest[i]].idCorrectAnswer[0] !== Number(answerTheQuestons[i].responceClient)) {
                    falseRes += 1;
                    idFalseResponceTest.push(Questions[arrIdTest[i]].id);
                    if (Questions[arrIdTest[i]].topic === "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений") {
                        falseResponceTestByTopic[0] += 1;
                    } else if (Questions[arrIdTest[i]].topic === "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей") {
                        falseResponceTestByTopic[1] += 1;
                    } else if (Questions[arrIdTest[i]].topic === "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока") {
                        falseResponceTestByTopic[2] += 1;
                    } 
                }
            } else {
                if (!isEqual(Questions[arrIdTest[i]].idCorrectAnswer, answerTheQuestons[i].responceClient)) {
                    falseRes += 1;
                    idFalseResponceTest.push(Questions[arrIdTest[i]].id);
                    if (Questions[arrIdTest[i]].topic === "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений") {
                        falseResponceTestByTopic[0] += 1;
                    } else if (Questions[arrIdTest[i]].topic === "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей") {
                        falseResponceTestByTopic[1] += 1;
                    } else if (Questions[arrIdTest[i]].topic === "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока") {
                        falseResponceTestByTopic[2] += 1;
                    }
                }
            }
        }

        return (falseRes);
    };

    const [rightResponceTest] = useState(countRightResponceTest);
    const [falseResponceTest] = useState(countFalseResponceTest);

    const allResponceByTopic = [
        Math.round(rightResponceTestByTopic[0] / (rightResponceTestByTopic[0] + falseResponceTestByTopic[0]) * 10),
        Math.round(rightResponceTestByTopic[1] / (rightResponceTestByTopic[1] + falseResponceTestByTopic[1]) * 10),
        Math.round(rightResponceTestByTopic[2] / (rightResponceTestByTopic[2] + falseResponceTestByTopic[2]) * 10),
    ];

    const finalTestScore = Math.round(rightResponceTest / (rightResponceTest + falseResponceTest) * 10);

    const data = {
        labels: [
            'Глава 1',
            'Глава 2',
            'Глава 3'],
        datasets: [
            {
                data: [
                    allResponceByTopic[0],
                    allResponceByTopic[1],
                    allResponceByTopic[2]],
                backgroundColor: 'rgba(5, 136, 119, 0.2)',
                borderColor: 'rgba(5, 136, 119, 1)',
                borderWidth: 1,
            },
        ],
    };

    return (
        <div className="container text-center">
            <div className="row">
                <h1>Системы тестирования компетенций врачебного коллектива многопрофильного стационара по вопросам безопасности медицинской деятельности</h1>
                <Divider sx={{borderBottomWidth: 3, backgroundColor: "#058877"}}/>
            </div>

            <div className="row justify-content-center">
                <div className="col-5"><h3>{"Правильных ответов: " + rightResponceTest + "/" + arrIdTest.length}</h3></div>
                <div className="col-2" />
                <div className="col-5"><h3>{"Неправильных ответов: " + falseResponceTest + "/" + arrIdTest.length}</h3></div>
            </div>

            <div className="row align-items-center my-5">
                <div className="col-7">
                    <Radar options={options} data={data} />
                </div>

                <div className="col-5">
                    <div className="row">
                        <p className="text-start fs-6 pb-2">Глава 1 - Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений</p>
                        <p className="text-start fs-6 pb-2">Глава 2 - Протокол профилактики катетер-ассоциированной инфекции мочевых путей</p>
                        <p className="text-start fs-6 pb-2">Глава 3 - Протокол профилактики катетер-ассоциированной инфекции кровотока</p>
                    </div>
                </div>
            </div>

            <div className="row justify-content-center mb-3">
                <div className="col-12"><h3>{"Ваша итоговая оценка: " + finalTestScore + " баллов"}</h3></div>
            </div>

            <button type="button" className="btn btn-light justify-content-center" style={{ backgroundColor: "#058877", color: "white" }} onClick={handleChange}>Перейти на главную страницу!</button>

            <div className="row text-start">
                <h2>Ошибки:</h2>
                {idFalseResponceTest.map((fal) =>
                    <div className="row">
                        <div className="row">
                            <h3>{"Вопрос: " + Questions[fal].question}</h3>
                        </div>

                        {Questions[fal].isSingleResponce &&
                            <div>
                                <h4>{"Правильный ответ: " + Questions[fal].idCorrectAnswer + ") " + Questions[fal].responseOptions[Questions[fal].idCorrectAnswer].answer}</h4>
                            </div>}

                        {!Questions[fal].isSingleResponce &&
                            <div>
                                <h4>{"Правильный ответ: " + Questions[fal].idCorrectAnswer.map((res) =>
                                    res + ") " + Questions[fal].responseOptions[res].answer)}</h4>
                            </div>}

                        {Questions[fal].isSingleResponce &&
                            <div>
                                <p>{"Ваш ответ: " + answerTheQuestons.filter(res => res.idQuestion === fal)[0].responceClient + ') ' +
                                    Questions[fal].responseOptions[answerTheQuestons.filter(res =>
                                        res.idQuestion === fal)[0].responceClient].answer}</p>
                            </div>
                        }

                        {!Questions[fal].isSingleResponce &&
                            <div>
                                <p>{"Ваш ответ: " + answerTheQuestons.filter(res => res.idQuestion === fal)[0].responceClient.map(
                                    (value) => value + ") " + Questions[fal].responseOptions[value].answer
                                )}</p>
                            </div>
                        }

                        <div>
                            <p />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};