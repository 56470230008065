
export const Questions = [
    {
        "id": 0,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "DOCTOR_ROLE",
        "question": "Какие требования по профилактике ВТЭО должен выполнить врач при ведении пациентов с предполагаемой длительностью госпитализации > 48 часов, не нуждающихся в приеме лечебных доз антикоагуклянтов?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "оценить риск ВТЭО и кровотечений только экстренно госпитализированным пациентам в первые 24 часа после госпитализации"
            },
            {
                "id": 1,
                "answer": "оценить риск ВТЭО и кровотечений всем (эксренно и планово госпитализироваггным) пациентам в первые 24 часа после госпитализации"
            },
            {
                "id": 2,
                "answer": "документировать риск ВТЭО в ИБ в первые 24 часа после госпитализации"
            },
            {
                "id": 3,
                "answer": "информировать пациента с высоким риском ВТЭО о необходимости проведения профилактики ВТЭО"
            },
            {
                "id": 4,
                "answer": "зарегистрировать метод тромбопрофилактики в листе назначений ИБ в течение 24 часов после госпитализации пациента"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            1,
            2,
            3,
            4
        ]
    },
    {
        "id": 1,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "DOCTOR_ROLE",
        "question": "При назначении эластической компрессии нижних конечностей в листе назначений ИБ врач указывает:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "вид эластической компрессии: эластическое бинтование, компрессионный трикотаж (чулки)"
            },
            {
                "id": 1,
                "answer": "кратность"
            },
            {
                "id": 2,
                "answer": "класс эластической компрессии"
            },
            {
                "id": 3,
                "answer": "размер чулок"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            2
        ]
    },
    {
        "id": 2,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "DOCTOR_ROLE",
        "question": "Переоценку риска ВТЭО и кровотечения обязательно проводят в следующих случаях:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "постановка / удаление центрального венозного катетера"
            },
            {
                "id": 1,
                "answer": "перевод пациента в ОРИТ"
            },
            {
                "id": 2,
                "answer": "изменение двигательного режима (например, иммобилизация дольше 72-x часов)"
            },
            {
                "id": 3,
                "answer": "выявление онкологического заболевания"
            },
            {
                "id": 4,
                "answer": "диагностика внутрибольничного инфаркта миокарда/инсульта"
            },
            {
                "id": 5,
                "answer": "внутригоспитальный перелом конечностей"
            },
            {
                "id": 6,
                "answer": "диагностика острой язвы желудка/12- перстной кишки"
            },
            {
                "id": 7,
                "answer": "развитие кровотечения"
            },
            {
                "id": 8,
                "answer": "тромбоцитопения"
            },
            {
                "id": 9,
                "answer": "снижение СКФ <30 мл/мин/м²"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9
        ]
    },
    {
        "id": 3,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "DOCTOR_ROLE",
        "question": "Противопоказаниями для механической профилактики являются:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "тяжелые заболевания периферических артерий (боль в покое, трофические изменения, пролежни, отсутствие пульсации артерий стоп)"
            },
            {
                "id": 1,
                "answer": "поражения кожных покровов нижних конечностей (экземы, дерматиты, микозы, гангрена, пересадка кожи)"
            },
            {
                "id": 2,
                "answer": "диабетическая стопа и /или периферическая нейропатия"
            },
            {
                "id": 3,
                "answer": "непереносимость материала, входящего в состав компрессионныго трикотажа / манжет ППК /эластичных бинтов"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2,
            3
        ]
    },
    {
        "id": 4,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "DOCTOR_ROLE",
        "question": "Специфическими противопоказаниями для ППК является:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "субдуральная и экстрадуральная гематома;"
            },
            {
                "id": 1,
                "answer": "острый тромбофлебит поверхностных вен или тромбоз глубоких вен;"
            },
            {
                "id": 2,
                "answer": "ТЭЛА"
            },
            {
                "id": 3,
                "answer": "инсульт"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2
        ]
    },
    {
        "id": 5,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "DOCTOR_ROLE",
        "question": "Для первичной профилактики ВТЭО в группах повышенного риска ВГ-ВТЭО используют трикотаж:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "профилактического класса компрессии (15-18 мм рт.ст)"
            },
            {
                "id": 1,
                "answer": "III класса компрессии (33-46 мм рт.ст.)"
            },
            {
                "id": 2,
                "answer": "II класса (23-32 мм рт.ст.)"
            },
            {
                "id": 3,
                "answer": "I класса компрессии (18-22 мм рт.ст.)"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    },
    {
        "id": 6,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "DOCTOR_ROLE",
        "question": "Для вторичной профилактики ВТЭО (ТГВ/ТЭЛА) применяют трикотаж:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "профилактического класса компрессии (15-18 мм рт.ст)"
            },
            {
                "id": 1,
                "answer": "II класса (23-32 мм рт.ст.)"
            },
            {
                "id": 2,
                "answer": "I класса компрессии (18-22 мм рт.ст.)"
            },
            {
                "id": 3,
                "answer": "III класса компрессии (33-46 мм рт.ст.)"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            1,
            3
        ]
    },
    {
        "id": 7,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "DOCTOR_ROLE",
        "question": "При назначении перемежающейся пневмокомпрессии в листе назначений обязательно указывается:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "сторона (двусторонняя)"
            },
            {
                "id": 1,
                "answer": "уровень (бедро, голень)"
            },
            {
                "id": 2,
                "answer": "режим (кратность, количество часов в день)"
            },
            {
                "id": 3,
                "answer": "общая длительность"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2,
            3
        ]
    },
    {
        "id": 8,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "DOCTOR_ROLE",
        "question": "Дозы эноксапарина (Клексана) для профилактики ВТЭО у остро заболевших нехирургических пациентов стационара",
        "responseOptions": [
            {
                "id": 0,
                "answer": "подкожно 20 мг 1 раз в сутки"
            },
            {
                "id": 1,
                "answer": "подкожно 60 мг 2 раз в сутки"
            },
            {
                "id": 2,
                "answer": "подкожно 40 мг 1 раза в сутки"
            },
            {
                "id": 3,
                "answer": "подкожно 1 мг/кг 2 раза в сутки"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            2
        ]
    },
    {
        "id": 9,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "DOCTOR_ROLE",
        "question": "Фармакопрофилактику ВТЭО следует проводить остро заболевшим нехирургическим пациентам, вынужденным соблюдать постельный режим, и в дополнение имеющим один из следующих факторов:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "ОНМК"
            },
            {
                "id": 1,
                "answer": "сепсис"
            },
            {
                "id": 2,
                "answer": "активный рак"
            },
            {
                "id": 3,
                "answer": "ВТЭО в анамнезе"
            },
            {
                "id": 4,
                "answer": "ИМТ≥25 кг/м²"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2,
            3
        ]
    },
    {
        "id": 10,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "DOCTOR_ROLE",
        "question": "С целью первичной фармакопрофилактики ВГ-ВТЭО у остро заболевших нехирургических пациентов стационара применяют:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "нефракционированный гепарин"
            },
            {
                "id": 1,
                "answer": "ацетилсалициловую кислоту (Аспирин)"
            },
            {
                "id": 2,
                "answer": "низкомолекулярные гепарины"
            },
            {
                "id": 3,
                "answer": "прямые пероральные антикоагулянты"
            },
            {
                "id": 4,
                "answer": "антагонисты витамина К "
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            2
        ]
    },
    {
        "id": 11,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "SURGEON_DOC_ROLE",
        "question": "Для медикаментозной профилактики венозных тромбоэмболических осложнений у пациентов хирургического профиля после вмешательства применяют:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "нефракционированный гепарин"
            },
            {
                "id": 1,
                "answer": "эноксапарин (Клексан)"
            },
            {
                "id": 2,
                "answer": "дабигатран (Прадакса)"
            },
            {
                "id": 3,
                "answer": "фондапаринукс (Арикстра)"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            3
        ]
    },
    {
        "id": 12,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "SURGEON_DOC_ROLE",
        "question": "Установка эпидурального катетера после введения профилактических доз низкомолекулярного гепарина (НМГ) противопоказана в течение: а. 8 часов",
        "responseOptions": [
            {
                "id": 0,
                "answer": "8 часов"
            },
            {
                "id": 1,
                "answer": "12 часов"
            },
            {
                "id": 2,
                "answer": "24 часов"
            },
            {
                "id": 3,
                "answer": "48 часов "
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 13,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "SURGEON_DOC_ROLE",
        "question": "Возобновление тромбопрофилактики ПОАК (ривароксабаном /апиксабаном /дабигатраном) при установленном эпидуральном катетере возможно:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "через 4 часа"
            },
            {
                "id": 1,
                "answer": "через 6 часов"
            },
            {
                "id": 2,
                "answer": "через 8 часов"
            },
            {
                "id": 3,
                "answer": "через 12 часов"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 14,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "SURGEON_DOC_ROLE",
        "question": "Возобновление тромбопрофилактики ПОАК (ривароксабаном /апиксабаном /дабигатраном) после удаления эпидурального катетера возможно:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "через 4 часа"
            },
            {
                "id": 1,
                "answer": "через 6 часов"
            },
            {
                "id": 2,
                "answer": "через 8 часов"
            },
            {
                "id": 3,
                "answer": "через 12 часов"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 15,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "NURSE_ROLE",
        "question": "При проведении механической профилактики ВТЭО медицинская сестра:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "проводит перемежающую пневмокомпрессию (ППК) по назначению врача."
            },
            {
                "id": 1,
                "answer": "самостоятельно определяет класс компрессии трикотажа или эластичного бинта"
            },
            {
                "id": 2,
                "answer": "самостоятельно определяет размер компрессионных чулок или бинта"
            },
            {
                "id": 3,
                "answer": "накладывает эластичные бинты и надевает компрессионный трикотаж по назначению врача"
            },
            {
                "id": 4,
                "answer": "информирует лечащего врача о нарушении пациентом режима механопрофилактики"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            2,
            3,
            4
        ]
    },
    {
        "id": 16,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "NURSE_ROLE",
        "question": "При оперативном лечении пациента медицинские сестры соблюдают следующие правила:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "выполняют эластическую компрессию (бинты или чулки) нижних конечностей пациента перед транспортировкой пациента в операционную"
            },
            {
                "id": 1,
                "answer": "выполняют эластическую компрессию (бинты или чулки) нижних конечностей пациента в операционной;"
            },
            {
                "id": 2,
                "answer": "проверяют фиксацию бинтов после операции;"
            },
            {
                "id": 3,
                "answer": "позволяют пациенту частично спустить чулок после операции"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            2
        ]
    },
    {
        "id": 17,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "NURSE_ROLE",
        "question": "Перед применением компресионного трикотажа медицинская сестра соблюдают следующие требования:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "в листе назначения ИБ уточняет, назначенный врачом, тип трикотажа (чулки) и класс компрессии"
            },
            {
                "id": 1,
                "answer": "объясняет пациенту важность ношения чулок в течение всего времени, предписанного врачом."
            },
            {
                "id": 2,
                "answer": "оценивает состояние кожных покровов и кровоснабжения нижних конечностей"
            },
            {
                "id": 3,
                "answer": "уточняет у пациента отсутствие аллергии на компоненты трикотажа."
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2,
            3
        ]
    },
    {
        "id": 18,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "NURSE_ROLE",
        "question": "При оценке состояния кожных покровов и кровоснабжения нижних конечностей у пациентов, которым проводится механическая профилактика ВТЭО, контролируют:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "цвет, отечность и температуру тканей, особенно кожи над суставами;"
            },
            {
                "id": 1,
                "answer": "ощущения онемения и чувство покалывания в ногах;"
            },
            {
                "id": 2,
                "answer": "пульсацию артерий обеих стоп."
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2
        ]
    },
    {
        "id": 19,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "NURSE_ROLE",
        "question": "Укажите необходимое оборудование для выполнения механической профилактики:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "измерительная лента (сантиметр)"
            },
            {
                "id": 1,
                "answer": "таблица для выбора размера МЭКИ"
            },
            {
                "id": 2,
                "answer": "тальк"
            },
            {
                "id": 3,
                "answer": "одноразовые перчатки"
            },
            {
                "id": 4,
                "answer": "фонендоскоп"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2,
            3
        ]
    },
    {
        "id": 20,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "NURSE_ROLE",
        "question": "Как часто необходимо пациенту снимать чулки для проверки состояния тканей нижних конечностей и проведения личной гигиены при механопрофилактике?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "ежедневно не больше, чем на 30 мин."
            },
            {
                "id": 1,
                "answer": "через день не больше, чем на 30 мин."
            },
            {
                "id": 2,
                "answer": "ежедневно на несколько часов."
            },
            {
                "id": 3,
                "answer": "ежедневно на ночь."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 21,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "NURSE_ROLE",
        "question": "Как часто проводится стирка или замена чулок?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "не реже 1 раза в неделю."
            },
            {
                "id": 1,
                "answer": "по мере загрязнения, но не реже, чем раз в 3 дня."
            },
            {
                "id": 2,
                "answer": "при наличии инфекции или проведении деколонизации у пациента смену /стирку чулок следует осуществлять ежедневно. "
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            1,
            2
        ]
    },
    {
        "id": 22,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "NURSE_ROLE",
        "question": "Укажите требования, которые необходимо выполнять при надевании пациенту чулок:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "усадить пациента на стуле или расположить в постели так, чтобы ноги не были согнуты в коленях"
            },
            {
                "id": 1,
                "answer": "с помощью сантиметровой ленты измерить окружность голени, лодыжки и бедра обеих конечностей"
            },
            {
                "id": 2,
                "answer": "с помощью сантиметровой ленты измерить длину ног"
            },
            {
                "id": 3,
                "answer": "подобрать необходимый размер чулок в соответствии с таблицей производителя чулок "
            },
            {
                "id": 4,
                "answer": "после надевания чулок попросить пациента согнуть и разогнуть ногу (самостоятельно или с помощью)"
            },
            {
                "id": 5,
                "answer": "через 30 минут после надевания чулок проверить отсутствие кожной реакции, отеков, онемения, ощущения дискомфорта у пациента"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2,
            3,
            4,
            5
        ]
    },
    {
        "id": 23,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "NURSE_ROLE",
        "question": "Кратность контроля положения чулок и отсутствия дискомфорта при проведении механопрофилактики методом компрессионного трикотажа /бинтования:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "не реже 2-х раз в сутки"
            },
            {
                "id": 1,
                "answer": "не реже 1 раза в сутки"
            },
            {
                "id": 2,
                "answer": "не реже 1 раза в 2 дня"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 24,
        "topic": "Глава 1. Протокол профилактики внутригоспитальных венозных тромбоэмболических осложнений",
        "roleTest": "NURSE_ROLE",
        "question": "Действия медсестры при выявлении отеков/покраснения кожи:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "позволить пациенту частично спустить чулок"
            },
            {
                "id": 1,
                "answer": "позволить пациенту длительно пребывать без чулок (больше 30 минут), чтобы «кожа подышала»"
            },
            {
                "id": 2,
                "answer": "помассировать покрасневшие участки кожи, увеличивая, тем самым, риск повреждения"
            },
            {
                "id": 3,
                "answer": "немедленно сообщить об этом врачу"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    },
    {
        "id": 25,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Внутрибольничная инфекция мочевых путей (ИМП):",
        "responseOptions": [
            {
                "id": 0,
                "answer": "насчитывает около 40% всех внутрибольничных инфекций"
            },
            {
                "id": 1,
                "answer": "большинство внутрибольничных ИМП связаны с мочевыми катетерами"
            },
            {
                "id": 2,
                "answer": "диагноз устанавливается при помутнении мочи"
            },
            {
                "id": 3,
                "answer": "риск возникновения инфекции возрастает при длительном использовании мочевого катетера"
            },
            {
                "id": 4,
                "answer": "все вышеперечисленное"
            },
            {
                "id": 5,
                "answer": "правильные ответы 1, 2 и 4"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            5
        ]
    },
    {
        "id": 26,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Протокол ухода за пациентами с недержанием:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "установите постоянный мочевой катетер"
            },
            {
                "id": 1,
                "answer": "не устанавливайте постоянный мочевой катетер"
            },
            {
                "id": 2,
                "answer": "используйте барьерные крема для кожи"
            },
            {
                "id": 3,
                "answer": "поворачивайте пациента каждые два часа, в случае необходимости осуществляйте помощь в передвижении"
            },
            {
                "id": 4,
                "answer": "начните учебную программу по самообслуживанию"
            },
            {
                "id": 5,
                "answer": "все вышеперечисленное "
            },
            {
                "id": 6,
                "answer": "правильные ответы под номерами 2, 3, 4, 5"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            6
        ]
    },
    {
        "id": 27,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Риск катетер-ассоциированной ИМП снижается при:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "отсутствии необоснованного использования мочевого катетера"
            },
            {
                "id": 1,
                "answer": "удалении катетера сразу же при исчезновении необходимости"
            },
            {
                "id": 2,
                "answer": "сохранении дренажной системы замкнутой"
            },
            {
                "id": 3,
                "answer": "все вышеперечисленное"
            },
            {
                "id": 4,
                "answer": "правильные ответы под номерами 1 и 3"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    },
    {
        "id": 28,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Показания к установке мочевого катетера:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "обструкция мочевых путей"
            },
            {
                "id": 1,
                "answer": "нейрогенный мочевой пузырь с задержкой мочи"
            },
            {
                "id": 2,
                "answer": "урологические операции"
            },
            {
                "id": 3,
                "answer": "недержание мочи"
            },
            {
                "id": 4,
                "answer": "все вышеперечисленное"
            },
            {
                "id": 5,
                "answer": "правильные ответы под номерами 1, 2 и 3"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            5
        ]
    },
    {
        "id": 29,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Показания к установке мочевого катетера:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "мониторинг диуреза в отделении интенсивной терапии"
            },
            {
                "id": 1,
                "answer": "иммобилизация вследствие возраста или общего состояния"
            },
            {
                "id": 2,
                "answer": "получение проб и посевов мочи у тех, кто способен к мочеиспусканию"
            },
            {
                "id": 3,
                "answer": "правильные ответы под номерами 1 и 2"
            },
            {
                "id": 4,
                "answer": "правильные ответы под номерами 1, 2 и 3"
            }
        ],
        "isSingleResponce":  true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 30,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Мочевой катетер показан для:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "тщательного мониторинга диуреза у пациентов вне ОРИТ"
            },
            {
                "id": 1,
                "answer": "пациентов, получающих внутривенные мочегонные"
            },
            {
                "id": 2,
                "answer": "пациентов с недержанием, требующих многократной смены постельного белья"
            },
            {
                "id": 3,
                "answer": "все вышеперечисленное"
            },
            {
                "id": 4,
                "answer": "ничего из вышеперечисленного"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            4
        ]
    },
    {
        "id": 31,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "При установке мочевого катетера необходимо:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "использование гигиены рук до и после установки"
            },
            {
                "id": 1,
                "answer": "использование стерильных перчаток, бинтов, антисептического раствора для очистки периуретральной области и одноразовой упаковки смазки (катеджель) для установки"
            },
            {
                "id": 2,
                "answer": "фиксация катетера для предотвращения тракции уретры"
            },
            {
                "id": 3,
                "answer": "использование максимально возможного размера катетера для пациента"
            },
            {
                "id": 4,
                "answer": "все вышеперечисленное"
            },
            {
                "id": 5,
                "answer": "правильные ответы под номерами 1, 2 и 3"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            5
        ]
    },
    {
        "id": 32,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Правильный уход за мочевым катетером включает:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "сохранение герметичности стерильной дренажной системы"
            },
            {
                "id": 1,
                "answer": "промывание катетера только при необходимости"
            },
            {
                "id": 2,
                "answer": "обработка периуретральной области антисептиками"
            },
            {
                "id": 3,
                "answer": "еженедельная замена катетера"
            },
            {
                "id": 4,
                "answer": "все вышеперечисленное"
            },
            {
                "id": 5,
                "answer": "правильные ответы под номерами 1 и 2"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            5
        ]
    },
    {
        "id": 33,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Оценка показаний для мочевого катетера должна проводиться:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "ежедневно"
            },
            {
                "id": 1,
                "answer": "дважды в неделю"
            },
            {
                "id": 2,
                "answer": "еженедельно"
            },
            {
                "id": 3,
                "answer": "каждые 2 недели"
            },
            {
                "id": 4,
                "answer": "ежемесячно"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 34,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Пациенту не следует устанавливать постоянный мочевой катетер в следующих случаях:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "если пациент имеет подтвержденное повреждение уретры"
            },
            {
                "id": 1,
                "answer": "при подозрении на наличие травмы уретры"
            },
            {
                "id": 2,
                "answer": "для контроля недержания мочи"
            },
            {
                "id": 3,
                "answer": "при остром простатите"
            },
            {
                "id": 4,
                "answer": "все вышеперечисленное верно"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            4
        ]
    },
    {
        "id": 35,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "До начала процедуры катетеризации пациент должен находиться в положении:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "на животе"
            },
            {
                "id": 1,
                "answer": "на спине"
            },
            {
                "id": 2,
                "answer": "Тренделенбурга"
            },
            {
                "id": 3,
                "answer": "в любом из вышеперечисленных"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 36,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Катетеризацию уретры допустимо выполнять не в стерильных условиях",
        "responseOptions": [
            {
                "id": 0,
                "answer": "верно"
            },
            {
                "id": 1,
                "answer": "неверно"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 37,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Перед началом процедуры первым действием должно быть:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "гигиеническая обработка рук"
            },
            {
                "id": 1,
                "answer": "надевание стерильных перчаток"
            },
            {
                "id": 2,
                "answer": "вскрытие набора для катетеризации и размещение одноразовой пеленки под пациентом"
            },
            {
                "id": 3,
                "answer": "туалет наружных половых органов"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 38,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Одноразовая пеленка должна быть помещена под пациента:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "после надевания стерильных перчаток"
            },
            {
                "id": 1,
                "answer": "перед мытьем рук"
            },
            {
                "id": 2,
                "answer": "до надевания стерильных перчаток"
            },
            {
                "id": 3,
                "answer": "после обработки отверстия мочеиспускательного канала"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 39,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Чтобы зафиксировать отверстие мочеиспускательного канала перед введением катетера у женщин следует использовать:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "ведущую (правую) руку"
            },
            {
                "id": 1,
                "answer": "не ведущую руку"
            },
            {
                "id": 2,
                "answer": "обе руки"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 40,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "При катетеризации мужчин для смещения крайней плоти (если имеется) и фиксирования полового члена нужно использовать:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "ведущую (правую) руку"
            },
            {
                "id": 1,
                "answer": "не ведущую руку"
            },
            {
                "id": 2,
                "answer": "обе руки"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 41,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "После контакта с половыми органами пациента рука не должна касаться катетера и стерильных инструментов.",
        "responseOptions": [
            {
                "id": 0,
                "answer": "верно"
            },
            {
                "id": 1,
                "answer": "неверно"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 42,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Если при введении катетера ощущается сопротивление, продвигайте катетер, пока не преодолеете сопротивление.",
        "responseOptions": [
            {
                "id": 0,
                "answer": "верно"
            },
            {
                "id": 1,
                "answer": "неверно"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 43,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "При катетеризации женщины катетер должен быть установлен:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "до уровня баллона"
            },
            {
                "id": 1,
                "answer": "до уровня бокового канала (бифуркация)"
            },
            {
                "id": 2,
                "answer": "до упора"
            },
            {
                "id": 3,
                "answer": "до появления мочи и затем еще на 2-2,5 см"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    },
    {
        "id": 44,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "При катетеризации мужчины катетер должен быть установлен:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "до уровня баллона"
            },
            {
                "id": 1,
                "answer": "до появления мочи и затем еще на 2-2,5 см"
            },
            {
                "id": 2,
                "answer": "до уровня бокового канала (бифуркация)"
            },
            {
                "id": 3,
                "answer": "до упора"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 45,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Баллон следует раздуть:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "после подтверждения правильного размещения катетера"
            },
            {
                "id": 1,
                "answer": "до введения катетера"
            },
            {
                "id": 2,
                "answer": "во время введения катетера"
            },
            {
                "id": 3,
                "answer": "после фиксации катетера"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 46,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Для раздувания баллона необходимо использовать:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "воздух"
            },
            {
                "id": 1,
                "answer": "физиологический раствор"
            },
            {
                "id": 2,
                "answer": "стерильную воду"
            },
            {
                "id": 3,
                "answer": "любое из вышеперечисленных"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 47,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Правильное размещение катетера может быть подтверждено появлением мочи.",
        "responseOptions": [
            {
                "id": 0,
                "answer": "верно"
            },
            {
                "id": 1,
                "answer": "неверно"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 48,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "После закрепления катетера, поместите мочеприемник:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "на полу под кроватью"
            },
            {
                "id": 1,
                "answer": "ниже уровня пациента"
            },
            {
                "id": 2,
                "answer": "на коленях пациента"
            },
            {
                "id": 3,
                "answer": "на стойку для капельниц"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 49,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Показания для мочевого катетера должны пересматриваться:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "каждую неделю"
            },
            {
                "id": 1,
                "answer": "каждые 3 дня"
            },
            {
                "id": 2,
                "answer": "ежедневно"
            },
            {
                "id": 3,
                "answer": "раз в месяц"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 50,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Перед удалением катетера баллон необходимо:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "частично сдуть"
            },
            {
                "id": 1,
                "answer": "не сдувать"
            },
            {
                "id": 2,
                "answer": "слить содержимое и заново наполнить солевым раствором"
            },
            {
                "id": 3,
                "answer": "полностью спустить"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    },
    {
        "id": 51,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Если есть трудности при удалении мочевого катетера необходимо проконсультироваться с урологом",
        "responseOptions": [
            {
                "id": 0,
                "answer": "верно"
            },
            {
                "id": 1,
                "answer": "неверно"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 52,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "При осмотре пациента Вы обнаружили, что нарушено соединение между катетером и мочеприемником. Какие из следующих мер необходимо предпринять?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "с помощью асептической техники восстановить соединение между катетером и мочеприемником."
            },
            {
                "id": 1,
                "answer": "удалить постоянный катетер и заменить его новым с использованием асептической техники."
            },
            {
                "id": 2,
                "answer": "заменить мочеприемник новым."
            },
            {
                "id": 3,
                "answer": "быстро соединить части системы, чтобы избежать подтекания мочи в постели пациента."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 53,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "При опорожнении мочеприемника важно: (Выберите все правильные ответы)",
        "responseOptions": [
            {
                "id": 0,
                "answer": "использовать индивидуальный контейнер для сбора мочи для каждого пациента."
            },
            {
                "id": 1,
                "answer": "избегать соприкосновения наконечника мочеприемника и нестерильного контейнера для сбора мочи."
            },
            {
                "id": 2,
                "answer": "мочеприемник следует опорожнять только один раз в смену (каждые 8-12 часов), для уменьшения риска заражения."
            },
            {
                "id": 3,
                "answer": "не допускать разбрызгивания мочи"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            3
        ]
    },
    {
        "id": 54,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Показаниями для установки мочевого катетера являются: (Выберите все правильные ответы)",
        "responseOptions": [
            {
                "id": 0,
                "answer": "мониторинг диуреза у пациентов в критическом состоянии"
            },
            {
                "id": 1,
                "answer": "эпидуральная анестезия / анестезия"
            },
            {
                "id": 2,
                "answer": "ведение пациентов с открытыми ранами крестца или промежности"
            },
            {
                "id": 3,
                "answer": "обеспечение комфорта при ведении терминальных больных"
            },
            {
                "id": 4,
                "answer": "использование в периоперационном периоде для отдельных процедур"
            },
            {
                "id": 5,
                "answer": "острая задержка мочи"
            },
            {
                "id": 6,
                "answer": "недержание мочи"
            },
            {
                "id": 7,
                "answer": "обеспечение активности пациента вне постели"
            },
            {
                "id": 8,
                "answer": "длительная иммобилизация"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            2,
            4,
            5
        ]
    },
    {
        "id": 55,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Что из нижеперечисленного имеет важное значение при установке и уходе за мочевым катетером (Выберите все правильные ответы)",
        "responseOptions": [
            {
                "id": 0,
                "answer": "предварительная проверка баллона для раздувания перед установкой."
            },
            {
                "id": 1,
                "answer": "санитарно-гигиеническая обработка рук непосредственно перед и после любой манипуляции с катетером"
            },
            {
                "id": 2,
                "answer": "обеспечение ежедневного ухода за катетером с мылом и водой."
            },
            {
                "id": 3,
                "answer": "использование катетеров, покрытых нитратом серебра, для предотвращения инфекции"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2
        ]
    },
    {
        "id": 56,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "В начале рабочего дня медсестра сообщает Вам о наличии мочевого катетера у поступившего пациента. На вопрос о продолжительности катетеризации Вы получаете ответ: «Я не знаю, но все в порядке: моча прозрачная, все подключено правильно». Исходя из этой информации, какие действия следует предпринять? (Выберите все правильные ответы):",
        "responseOptions": [
            {
                "id": 0,
                "answer": "определите, существуют ли показания для постоянного катетера в настоящее время."
            },
            {
                "id": 1,
                "answer": "необходимо заменить мочеприемник на стерильный для уменьшения риска заражения"
            },
            {
                "id": 2,
                "answer": "уточните дату и время установки катетера на этикетке мочеприемника."
            },
            {
                "id": 3,
                "answer": "свяжитесь с предыдущим лечащим врачом, чтобы определить дату и время установки катетера."
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            2,
            3
        ]
    },
    {
        "id": 57,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Что из нижеперечисленного относится к критериям катетер-ассоциированной инфекции мочевых путей?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "на момент регистрации события, мочевой катетер был установлен уже в течение > 2 календарных дней (день установки считается первым днем)"
            },
            {
                "id": 1,
                "answer": "мочевой катетер находился в мочевом пузыре в день события или был удален за день до него"
            },
            {
                "id": 2,
                "answer": "положительный посев мочи с не более чем двумя видами микроорганизмов, один из которых в количестве >105 КОЕ/мл"
            },
            {
                "id": 3,
                "answer": "лихорадка (> 38,0С)"
            },
            {
                "id": 4,
                "answer": "резкие позывы к мочеиспусканию"
            },
            {
                "id": 5,
                "answer": "учащенное мочеиспускание"
            },
            {
                "id": 6,
                "answer": "дизурия"
            },
            {
                "id": 7,
                "answer": "болезненность в поясничной или надлобковой области"
            },
            {
                "id": 8,
                "answer": "все вышеперечисленное верно"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            8
        ]
    },
    {
        "id": 58,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Какие из указанных методов могут служить альтернативой постоянной катетеризации?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "УЗИ-сканирование мочевого пузыря для верификации задержки мочи"
            },
            {
                "id": 1,
                "answer": "ежедневное взвешивание пациента или взвешивание памперсов"
            },
            {
                "id": 2,
                "answer": "уропрезервативы у мужчин"
            },
            {
                "id": 3,
                "answer": "прикроватное судно"
            },
            {
                "id": 4,
                "answer": "периодическая катетеризация"
            },
            {
                "id": 5,
                "answer": "Все вышеперечисленное верно"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            5
        ]
    },
    {
        "id": 59,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Укажите временной интервал, в течение которого необходимо документировать в ИБ постановку уретрального катетера",
        "responseOptions": [
            {
                "id": 0,
                "answer": "24 часа"
            },
            {
                "id": 1,
                "answer": "1 час"
            },
            {
                "id": 2,
                "answer": "первые 3 суток"
            },
            {
                "id": 3,
                "answer": "4 часа"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    },
    {
        "id": 60,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Ежедневное заполнение чек-листа ведения уретрального катетера является необязательным",
        "responseOptions": [
            {
                "id": 0,
                "answer": "верно"
            },
            {
                "id": 1,
                "answer": "неверно"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 61,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Укажите сроки замены уретрального катетера",
        "responseOptions": [
            {
                "id": 0,
                "answer": "каждые 7 дней"
            },
            {
                "id": 1,
                "answer": "каждые 28 дней"
            },
            {
                "id": 2,
                "answer": "сроки определяются индивидуально в зависимости от наличия показаний к замене"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 62,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Все развившееся осложнения в течение катетеризации необходимо отмечать в чек-листе",
        "responseOptions": [
            {
                "id": 0,
                "answer": "верно"
            },
            {
                "id": 1,
                "answer": "неверно"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 63,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Выберите верные утверждения",
        "responseOptions": [
            {
                "id": 0,
                "answer": "чек-лист заполняется только врачом"
            },
            {
                "id": 1,
                "answer": "чек-лист заполняется только медсестрой"
            },
            {
                "id": 2,
                "answer": "чек-лист заполняется врачом и медсестрой"
            },
            {
                "id": 3,
                "answer": "допускается оставлять незаполненными пункты чек-листа"
            },
            {
                "id": 4,
                "answer": "чек-лист необходимо заполнять ежедневно"
            },
            {
                "id": 5,
                "answer": "все пункты чек-листа должны быть заполнены"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            2,
            4,
            5
        ]
    },
    {
        "id": 64,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Укажите предпочтительное время для удаления уретрального катетера",
        "responseOptions": [
            {
                "id": 0,
                "answer": "рано утром"
            },
            {
                "id": 1,
                "answer": "в конце рабочего дня"
            },
            {
                "id": 2,
                "answer": "время не имеет значения"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 65,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "Укажите ситуации, при которых срок катетеризации не должен превышать 24 часов",
        "responseOptions": [
            {
                "id": 0,
                "answer": "интраоперационный уретральный катетер"
            },
            {
                "id": 1,
                "answer": "ведение пациентов с открытыми ранами крестца или промежности"
            },
            {
                "id": 2,
                "answer": "острая задержка мочи"
            },
            {
                "id": 3,
                "answer": "сбор суточной мочи для диагностического исследования"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            3
        ]
    },
    {
        "id": 66,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "DOCTOR_ROLE",
        "question": "В течение какого периода после удаления МК необходимо контролировать мочеиспускание пациента?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "в течение суток каждые 4-6 часов"
            },
            {
                "id": 1,
                "answer": "в течение 1 часа"
            },
            {
                "id": 2,
                "answer": "в течение 6 часов каждые 2 часа"
            },
            {
                "id": 3,
                "answer": "в течение 12 часов каждые 4 часа"
            },
            {
                "id": 4,
                "answer": "нет необходимости в контроле мочеиспускания"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 67,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "При установке мочевого катетера необходимо:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "использование гигиены рук до и после установки"
            },
            {
                "id": 1,
                "answer": "использование стерильных перчаток, бинтов, антисептического раствора для очистки периуретральной области и одноразового пакета со смазкой для установки"
            },
            {
                "id": 2,
                "answer": "защита катетера для предотвращения движения и тракции уретры"
            },
            {
                "id": 3,
                "answer": "использование максимально возможного размера катетера для пациента"
            },
            {
                "id": 4,
                "answer": "правильные ответы под номерами 1, 2 и 3"
            },
            {
                "id": 5,
                "answer": "все вышеперечисленное"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            4
        ]
    },
    {
        "id": 68,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "Правильный уход за мочевым катетером включает:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "сохранение замкнутости стерильной дренажной системы"
            },
            {
                "id": 1,
                "answer": "промывание катетера только при необходимости"
            },
            {
                "id": 2,
                "answer": "обработка периуретральной области антисептиками"
            },
            {
                "id": 3,
                "answer": "еженедельная замена катетера"
            },
            {
                "id": 4,
                "answer": "правильные ответы под номерами 1 и 2"
            },
            {
                "id": 5,
                "answer": "все вышеперечисленное"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            4
        ]
    },
    {
        "id": 69,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "До начала процедуры катетеризации пациент должен находиться в положении:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "на животе"
            },
            {
                "id": 1,
                "answer": "на спине"
            },
            {
                "id": 2,
                "answer": "Тренделенбурга"
            },
            {
                "id": 3,
                "answer": "в любом из вышеперечисленных"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 70,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "Катетеризацию уретры допустимо выполнять не в стерильных условиях.",
        "responseOptions": [
            {
                "id": 0,
                "answer": "верно"
            },
            {
                "id": 1,
                "answer": "неверно"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 71,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "Перед началом процедуры первым действием должно быть:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "гигиена рук"
            },
            {
                "id": 1,
                "answer": "открытие и надевание стерильных перчаток"
            },
            {
                "id": 2,
                "answer": "открытие набора и размещение абсорбирующей прокладки"
            },
            {
                "id": 3,
                "answer": "закрытие области катетеризации"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 72,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "Абсорбирующая прокладка должна быть помещена под пациента:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "после надевания стерильных перчаток"
            },
            {
                "id": 1,
                "answer": "перед выполнением гигиены рук"
            },
            {
                "id": 2,
                "answer": "до надевания стерильных перчаток"
            },
            {
                "id": 3,
                "answer": "после обработки отверстия мочеиспускательного канала"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 73,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "При катетеризации следует использовать ______, чтобы найти мочеиспускательный канал.",
        "responseOptions": [
            {
                "id": 0,
                "answer": "преобладающую (ведущую) руку"
            },
            {
                "id": 1,
                "answer": "не ведущую руку"
            },
            {
                "id": 2,
                "answer": "обе руки"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 74,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "При катетеризации пациента мужского пола, вы должны использовать ________ для смещения крайней плоти (если имеется) и фиксирования полового члена.",
        "responseOptions": [
            {
                "id": 0,
                "answer": "преобладающую (ведущую) руку"
            },
            {
                "id": 1,
                "answer": "не ведущую руку"
            },
            {
                "id": 2,
                "answer": "обе руки"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 75,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "После контакта с половыми органами пациента данная рука в настоящее время считается нестерильной и не должна перемещаться из этого положения.",
        "responseOptions": [
            {
                "id": 0,
                "answer": "верно"
            },
            {
                "id": 1,
                "answer": "неверно"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 76,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "Если при введении катетера ощущается сопротивление, продвигайте катетер, пока не преодолеете сопротивление.",
        "responseOptions": [
            {
                "id": 0,
                "answer": "верно"
            },
            {
                "id": 1,
                "answer": "неверно"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 77,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "При катетеризации женщины катетер должен быть установлен:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "до уровня баллона"
            },
            {
                "id": 1,
                "answer": "до уровня бокового канала (бифуркация)"
            },
            {
                "id": 2,
                "answer": "до упора"
            },
            {
                "id": 3,
                "answer": "до появления мочи и затем еще на 2-2,5 см"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    },
    {
        "id": 78,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "При катетеризации мужчины катетер должен быть установлен:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "до уровня баллона"
            },
            {
                "id": 1,
                "answer": "до появления мочи и затем еще на 2-2,5 см"
            },
            {
                "id": 2,
                "answer": "до уровня бокового канала (бифуркация)"
            },
            {
                "id": 3,
                "answer": "до упора"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 79,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "Баллон следует раздуть:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "после подтверждения правильного размещения катетера"
            },
            {
                "id": 1,
                "answer": "до введения катетера"
            },
            {
                "id": 2,
                "answer": "во время введения катетера"
            },
            {
                "id": 3,
                "answer": "после защиты катетера"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 80,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "Для раздувания баллона. необходимо использовать",
        "responseOptions": [
            {
                "id": 0,
                "answer": "воздух"
            },
            {
                "id": 1,
                "answer": "физ раствор"
            },
            {
                "id": 2,
                "answer": "стерильную воду"
            },
            {
                "id": 3,
                "answer": "любое из вышеперечисленных"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 81,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "Правильное размещение катетера может быть подтверждено появлением мочи.",
        "responseOptions": [
            {
                "id": 0,
                "answer": "верно"
            },
            {
                "id": 1,
                "answer": "неверно"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 82,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "После закрепления катетера, поместите мочеприемник:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "на полу под кроватью"
            },
            {
                "id": 1,
                "answer": "ниже уровня пациента"
            },
            {
                "id": 2,
                "answer": "на коленях пациента"
            },
            {
                "id": 3,
                "answer": "на стойку для капельниц"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 83,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "Перед удалением катетера баллон необходимо:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "частично сдуть"
            },
            {
                "id": 1,
                "answer": "не сдувать"
            },
            {
                "id": 2,
                "answer": "слить содержимое и заново наполнить солевым раствором"
            },
            {
                "id": 3,
                "answer": "полностью спустить"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    },
    {
        "id": 84,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "Если есть трудности при удалении мочевого катетера необходимо проконсультироваться с более опытным специалистом.",
        "responseOptions": [
            {
                "id": 0,
                "answer": "верно"
            },
            {
                "id": 1,
                "answer": "неверно"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },






    
    {
        "id": 85,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "При осмотре пациента Вы обнаружили нарушение соединения между катетером и мочеприемником. Какие из следующих мер необходимо предпринять?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "с помощью асептической техники восстановить соединение между катетером и мочеприемником."
            },
            {
                "id": 1,
                "answer": "удалить постоянный катетер и заменить его новым с использованием асептической техники."
            },
            {
                "id": 2,
                "answer": "заменить мочеприемник новым."
            },
            {
                "id": 3,
                "answer": "быстро соединить части системы, чтобы избежать дальнейшего накопления влаги в постели пациента."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 86,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "При опорожнении мочеприемника: (Выберите все правильные ответы)",
        "responseOptions": [
            {
                "id": 0,
                "answer": "используйте индивидуальный контейнер для сбора мочи для каждого пациента."
            },
            {
                "id": 1,
                "answer": "избегайте соприкосновения наконечника мочеприемника и нестерильного контейнера для сбора мочи."
            },
            {
                "id": 2,
                "answer": "мочеприемник следует опорожнять только один раз в смену (каждые 8-12 часов), для уменьшения риска заражения."
            },
            {
                "id": 3,
                "answer": "не допускайте разбрызгивания."
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            3
        ]
    },
    {
        "id": 87,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "Что из нижеперечисленного имеет большое значение при установке и уходе за катетером Фолея (Выберите все правильные ответы)",
        "responseOptions": [
            {
                "id": 0,
                "answer": "предварительная проверка баллона для раздувания перед установкой."
            },
            {
                "id": 1,
                "answer": "гигиена рук непосредственно перед и после любой манипуляции с катетером Фолея."
            },
            {
                "id": 2,
                "answer": "обеспечение ежедневного ухода за катетером Фолея с мылом и водой."
            },
            {
                "id": 3,
                "answer": "использование катетеров, покрытых нитратом серебра, для предотвращения инфекции"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2
        ]
    },
    {
        "id": 88,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "Укажите временной интервал, в течение которого необходимо документировать в ИБ постановку уретрального катетера",
        "responseOptions": [
            {
                "id": 0,
                "answer": "24 часа"
            },
            {
                "id": 1,
                "answer": "1 час"
            },
            {
                "id": 2,
                "answer": "первые 3 суток"
            },
            {
                "id": 3,
                "answer": "4 часа"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    },
    {
        "id": 89,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "Ежедневное заполнение чек-листа ведения уретрального катетера является необязательным",
        "responseOptions": [
            {
                "id": 0,
                "answer": "верно"
            },
            {
                "id": 1,
                "answer": "неверно"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 90,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "Выберите верные утверждения",
        "responseOptions": [
            {
                "id": 0,
                "answer": "чек-лист заполняется только врачом"
            },
            {
                "id": 1,
                "answer": "чек-лист заполняется только медсестрой"
            },
            {
                "id": 2,
                "answer": "чек-лист заполняется врачом и медсестрой"
            },
            {
                "id": 3,
                "answer": "допускается оставлять незаполненными пункты чек-листа"
            },
            {
                "id": 4,
                "answer": "чек-лист необходимо заполнять ежедневно"
            },
            {
                "id": 5,
                "answer": "все пункты чек-листа должны быть заполнены"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            2,
            4,
            5
        ]
    },
    {
        "id": 91,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "Что из нижеперечисленного верно?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "замена мочеприемника проводится только по назначению врача"
            },
            {
                "id": 1,
                "answer": "опорожнение мочеприемника проводится по мере наполнения (на 2/3 объема)"
            },
            {
                "id": 2,
                "answer": "факт замены и опорожнения мочеприемника необязательно документировать в чек-листе"
            },
            {
                "id": 3,
                "answer": "мочеприемник необходимо заменять каждые 7 дней или в соответствии с рекомендациями производителей и клинической необходимостью"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            1,
            3
        ]
    },
    {
        "id": 92,
        "topic": "Глава 2. Протокол профилактики катетер-ассоциированной инфекции мочевых путей",
        "roleTest": "NURSE_ROLE",
        "question": "Сколько времени необходимо контролировать мочеиспускание пациента после удаления МК?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "в течение суток каждые 4-6 часов"
            },
            {
                "id": 1,
                "answer": "в течение 1 часа"
            },
            {
                "id": 2,
                "answer": "в течение 6 часов каждые 2 часа"
            },
            {
                "id": 3,
                "answer": "в течение 12 часов каждые 4 часа"
            },
            {
                "id": 4,
                "answer": "нет необходимости в контроле мочеиспускания"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 93,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "DOCTOR_ROLE",
        "question": "Отметьте все правильные утверждения:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "КАИК является одной из 4 самых частых внутрибольничных инфекций"
            },
            {
                "id": 1,
                "answer": "90% инфекций кровотока связаны с центральным катетером"
            },
            {
                "id": 2,
                "answer": "профилактика КАИК основана на использовании антибиотиков"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1
        ]
    },
    {
        "id": 94,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "DOCTOR_ROLE",
        "question": "Отметьте все правильные утверждения:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "КАИК продлевает срок госпитализации, в среднем, на 7 дней"
            },
            {
                "id": 1,
                "answer": "КАИК увеличивает риск смертельного исхода на 10-20%"
            },
            {
                "id": 2,
                "answer": "Центральные венозные катетеры с антимикробным покрытием рекомендованы для краткосрочной (менее 5 дней) и долговременной катетеризации"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1
        ]
    },
    {
        "id": 95,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "DOCTOR_ROLE",
        "question": "Показания для катетеризации центральных вен включают:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "необходимость динамического контроля центрального венозного давления"
            },
            {
                "id": 1,
                "answer": "потребность в длительном введении инторопных и вазопрессорных препаратов"
            },
            {
                "id": 2,
                "answer": "проведение трансвенозной электрокардиостимуляции"
            },
            {
                "id": 3,
                "answer": "полное парентеральное питание"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2,
            3
        ]
    },
    {
        "id": 96,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "DOCTOR_ROLE",
        "question": "Где должен располагаться кончик правильно установленного центрального венозного катетера (ЦВК)?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "в яремной вене"
            },
            {
                "id": 1,
                "answer": "в подключичной вене"
            },
            {
                "id": 2,
                "answer": "в верхней или нижней полой вене"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 97,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "DOCTOR_ROLE",
        "question": "Для установки чрескожного ЦВК у взрослых пациентов в неосложненных случаях предпочтительна:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "внутренняя яремная вена"
            },
            {
                "id": 1,
                "answer": "подключичная вена"
            },
            {
                "id": 2,
                "answer": "бедренная вена"
            },
            {
                "id": 3,
                "answer": "наружная яремная вена"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 98,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "DOCTOR_ROLE",
        "question": "При любом доступе предпочтительной стороной для установки ЦВК является:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "правая"
            },
            {
                "id": 1,
                "answer": "левая"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 99,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "DOCTOR_ROLE",
        "question": "Тактика ведения ЦВК, установленных в экстренных условиях:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "не отличается от тактики ведения ЦВК, установленных в плановом порядке"
            },
            {
                "id": 1,
                "answer": "требует замены ЦВК в течение 48 часов"
            },
            {
                "id": 2,
                "answer": "требует замены ЦВК в течение 72 часов"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 100,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "DOCTOR_ROLE",
        "question": "Риск развития КАИК выше, если катетер установлен в:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "яремной вене"
            },
            {
                "id": 1,
                "answer": "подключичной вене"
            },
            {
                "id": 2,
                "answer": "периферической вене"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 101,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "DOCTOR_ROLE",
        "question": "Риск бактериальной колонизации ЦВК выше, если катетер установлен в:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "яремной вене"
            },
            {
                "id": 1,
                "answer": "подключичной вене"
            },
            {
                "id": 2,
                "answer": "бедренной вене"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 102,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "DOCTOR_ROLE",
        "question": "КАИК определяется как инфекция кровотока, резвившаяся после установки ЦВК:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "через 12 часов"
            },
            {
                "id": 1,
                "answer": "через 24 часа"
            },
            {
                "id": 2,
                "answer": "через 48 часов"
            },
            {
                "id": 3,
                "answer": "через 72 часа"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 103,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "DOCTOR_ROLE",
        "question": "Что из нижеперечисленного входит в перечень компонентов “care bundle”, требующих обязательного выполнения для снижения риска КАИК?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "гигиена рук"
            },
            {
                "id": 1,
                "answer": "использование УЗИ при установке ЦВК"
            },
            {
                "id": 2,
                "answer": "максимальные барьерные меры предосторожности"
            },
            {
                "id": 3,
                "answer": "проверка наличия информированного согласия"
            },
            {
                "id": 4,
                "answer": "обработка кожи раствором антисептика"
            },
            {
                "id": 5,
                "answer": "разметка перманентным маркером места установки ЦВК"
            },
            {
                "id": 6,
                "answer": "оптимальный выбор сосудистого доступа"
            },
            {
                "id": 7,
                "answer": "ежедневная оценка показаний к ЦВК"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            2,
            4,
            6,
            7
        ]
    },
    {
        "id": 104,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "DOCTOR_ROLE",
        "question": "При уходе за ЦВК прозрачную полупроницаемую повязку следует менять:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "ежедневно"
            },
            {
                "id": 1,
                "answer": "не реже, чем каждые 7 дней"
            },
            {
                "id": 2,
                "answer": "немедленно при скоплении влаги, крови, при отклеивании или загрязнении"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            1,
            2
        ]
    },
    {
        "id": 105,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "DOCTOR_ROLE",
        "question": "У пациента М. с установленным ЦВК поднялась температура, в месте пункции обнаружено покраснение и небольшое нагноение. Ваши действия?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "назначить ванкомицин"
            },
            {
                "id": 1,
                "answer": "если ЦВК еще необходим, назначить перестановку ЦВК через тот же доступ и оценить необходимость назначения антибиотиков"
            },
            {
                "id": 2,
                "answer": "если ЦВК еще необходим, назначить перестановку ЦВК через другой доступ и оценить необходимость назначения антибиотиков"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 106,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "DOCTOR_ROLE",
        "question": "Отметьте все правильные утверждения:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "услуги по установке и удалению ЦКВ регистрируются в ИБ"
            },
            {
                "id": 1,
                "answer": "на каждого пациента с ЦВК заводится чек-лист"
            },
            {
                "id": 2,
                "answer": "в состав бригады по установке ЦВК должно входить не менее 3 человек"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1
        ]
    },
    {
        "id": 107,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "DOCTOR_ROLE",
        "question": "В течение какого времени после удаления ЦВК следует продолжать наблюдение за местом доступа и менять повязку?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "24 часа"
            },
            {
                "id": 1,
                "answer": "72 часа"
            },
            {
                "id": 2,
                "answer": "7 дней"
            },
            {
                "id": 3,
                "answer": "До момента эпителизации раны"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    },
    {
        "id": 108,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "DOCTOR_ROLE",
        "question": "Забор крови из ЦВК в дополнение к забору из периферической вены может осуществляться:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "сразу после постановки нового ЦВК врачом, установившим катетер"
            },
            {
                "id": 1,
                "answer": "если нет другого доступа"
            },
            {
                "id": 2,
                "answer": "для определения контаминации катетера (просвета катетера)"
            },
            {
                "id": 3,
                "answer": "рутинно"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2
        ]
    },
    {
        "id": 109,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "DOCTOR_ROLE",
        "question": "Отметьте все правильные утверждения:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "после удаления ЦВК кончик катетера всегда отправляют на посев"
            },
            {
                "id": 1,
                "answer": "посев кончика ЦВК назначают для подтверждения источника бактеремии одновременно с посевами периферической крови"
            },
            {
                "id": 2,
                "answer": "набор для посева крови включает, как минимум, 2 флакона для посева аэробной и анаэробной культур"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            1,
            2
        ]
    },
    {
        "id": 110,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "DOCTOR_ROLE",
        "question": "СППР «Инфекция кровотока» заполняется:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "при подозрении на КАИК"
            },
            {
                "id": 1,
                "answer": "для каждого пациента, у которого установлен ЦВК"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 111,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "NURSE_ROLE",
        "question": "Отметьте все правильные утверждения:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "КАИК является одной из 4 самых частых внутрибольничных инфекций"
            },
            {
                "id": 1,
                "answer": "90% инфекций кровотока связаны с центральным катетером"
            },
            {
                "id": 2,
                "answer": "профилактика КАИК основана на использовании антибиотиков"
            },
            {
                "id": 3,
                "answer": "КАИК увеличивает срок госпитализации, в среднем, на 7 дней, а риск смертельного исхода на 10-20%"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            3
        ]
    },
    {
        "id": 112,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "NURSE_ROLE",
        "question": "Где должен располагаться кончик правильно установленного центрального венозного катетера (ЦВК)?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "в яремной вене"
            },
            {
                "id": 1,
                "answer": "в подключичной вене"
            },
            {
                "id": 2,
                "answer": "в верхней или нижней полой вене"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 113,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "NURSE_ROLE",
        "question": "Тактика ведения ЦВК, установленных в экстренных условиях:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "не отличается от тактики ведения ЦВК, установленных в плановом порядке"
            },
            {
                "id": 1,
                "answer": "требует замены ЦВК в течение 48 часов"
            },
            {
                "id": 2,
                "answer": "требует замены ЦВК в течение 72 часов"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 114,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "NURSE_ROLE",
        "question": "КАИК определяется как инфекция кровотока, развившаяся после установки ЦВК:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "через 12 часов"
            },
            {
                "id": 1,
                "answer": "через 24 часа"
            },
            {
                "id": 2,
                "answer": "через 48 часов"
            },
            {
                "id": 3,
                "answer": "через 72 часа"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 115,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "NURSE_ROLE",
        "question": "Что из нижеперечисленного входит в перечень компонентов “care bundle”, требующих обязательного выполнения для снижения риска КАИК?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "гигиена рук"
            },
            {
                "id": 1,
                "answer": "использование УЗИ при установке ЦВК"
            },
            {
                "id": 2,
                "answer": "максимальные барьерные меры предосторожности"
            },
            {
                "id": 3,
                "answer": "проверка наличия информированного согласия"
            },
            {
                "id": 4,
                "answer": "обработка кожи раствором антисептика"
            },
            {
                "id": 5,
                "answer": "разметка перманентным маркером места установки ЦВК"
            },
            {
                "id": 6,
                "answer": "оптимальный выбор сосудистого доступа"
            },
            {
                "id": 7,
                "answer": "ежедневная оценка показаний к ЦВК"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            2,
            4,
            6,
            7
        ]
    },
    {
        "id": 116,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "NURSE_ROLE",
        "question": "При уходе за ЦВК прозрачную полупроницаемую повязку следует менять:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "ежедневно"
            },
            {
                "id": 1,
                "answer": "не реже, чем каждые 7 дней"
            },
            {
                "id": 2,
                "answer": "немедленно при скоплении влаги, крови, при отклеивании или загрязнении"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            1,
            2
        ]
    },
    {
        "id": 117,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "NURSE_ROLE",
        "question": "В каком положении должен находиться пациент при смене повязки?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "на боку"
            },
            {
                "id": 1,
                "answer": "с приподнятым изголовьем"
            },
            {
                "id": 2,
                "answer": "в положении Тренделенбурга"
            },
            {
                "id": 3,
                "answer": "положение не имеет значения"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    },
    {
        "id": 118,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "NURSE_ROLE",
        "question": "Отметьте все правильные утверждения:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "при смене повязки необходимо соблюдать асептику, включая использование стерильной пеленки и стерильных перчаток"
            },
            {
                "id": 1,
                "answer": "при смене повязки на пациента всегда надевают маску"
            },
            {
                "id": 2,
                "answer": "при смене повязки на пациента надевают маску, если он не может сдержать кашель"
            },
            {
                "id": 3,
                "answer": "место установки ЦВК осматривают 1 раз в неделю "
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            2
        ]
    },
    {
        "id": 119,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "NURSE_ROLE",
        "question": "При смене повязки",
        "responseOptions": [
            {
                "id": 0,
                "answer": "кожу обрабатывают раствором антисептика в течение минимум 30 секунд"
            },
            {
                "id": 1,
                "answer": "кожу обрабатывают физраствором или моющим средством"
            },
            {
                "id": 2,
                "answer": "факт смены повязки отмечают в чек-листе"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            2
        ]
    },
    {
        "id": 120,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "NURSE_ROLE",
        "question": "Отметьте все правильные утверждения:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "канюлю ЦВК перед введением лекарственных средств следует обработать спиртосодержащим антисептиком"
            },
            {
                "id": 1,
                "answer": "промывать ЦВК необходимо только перед болюсным введением лекарств"
            },
            {
                "id": 2,
                "answer": "системы, используемые для вливания химиотерапевтических препаратов, необходимо заменить в течение 24 часов после окончания инфузии"
            },
            {
                "id": 3,
                "answer": "промывать катетер необходимо до и после забора крови из катетера"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            3
        ]
    },
    {
        "id": 121,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "NURSE_ROLE",
        "question": "Для промывания ЦВК используют стерильный физраствор объемом",
        "responseOptions": [
            {
                "id": 0,
                "answer": "не менее 2 мл в каждый канал"
            },
            {
                "id": 1,
                "answer": "не менее 5 мл в каждый канал"
            },
            {
                "id": 2,
                "answer": "не менее 10 мл в каждый канал"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 122,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "NURSE_ROLE",
        "question": "Отметьте все правильные утверждения:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "по окончании инфузии промывать катетер не следует"
            },
            {
                "id": 1,
                "answer": "компоненты крови следует переливать с помощью инфузионных систем, специально предназначенных для этой цели"
            },
            {
                "id": 2,
                "answer": "системы, использованные для вливания химиотерапевтических препаратов, необходимо заменять сразу же после завершения инфузии"
            },
            {
                "id": 3,
                "answer": "краны для катетера меняются вместе с инфузионной системой"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            1,
            2,
            3
        ]
    },
    {
        "id": 123,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "NURSE_ROLE",
        "question": "Замену инфузионной системы для вливания пропофола и жировых эмульсий",
        "responseOptions": [
            {
                "id": 0,
                "answer": "не позднее, чем через 12 часов"
            },
            {
                "id": 1,
                "answer": "в течение суток"
            },
            {
                "id": 2,
                "answer": "через 72 часа"
            },
            {
                "id": 3,
                "answer": "замена системы не обязательна"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 124,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "NURSE_ROLE",
        "question": "При взятии ЦВК на бактериальный посев:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "отрезают кончик не менее 10 см катетера и помещают в обычную пробирку"
            },
            {
                "id": 1,
                "answer": "отрезают кончик 5 см катетера и помещают в стерильную пробирку"
            },
            {
                "id": 2,
                "answer": "пробирку с катетером передают в лабораторию в течение суток"
            },
            {
                "id": 3,
                "answer": "пробирку с катетером передают в лабораторию в течение 4 часов"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 125,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "NURSE_ROLE",
        "question": "Отметьте все правильные утверждения:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "на каждого пациента с ЦВК заводится чек-лист по профилактике КАИК"
            },
            {
                "id": 1,
                "answer": "чек-лист заполняется ежедневно"
            },
            {
                "id": 2,
                "answer": "чек-лист распечатывается и вкладывается в ИБ для всех пациентов с ЦВК"
            },
            {
                "id": 3,
                "answer": "распечатанный чек-лист и вкладывается в ИБ, если у пациента развилась КАИК"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            3
        ]
    },
    {
        "id": 126,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "NURSE_ROLE",
        "question": "Забор крови из ЦВК в дополнение к забору из периферической вены может осуществляться:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "сразу после постановки нового ЦВК врачом, установившим катетер"
            },
            {
                "id": 1,
                "answer": "если нет другого доступа"
            },
            {
                "id": 2,
                "answer": "для определения контаминации катетера (просвета катетера)"
            },
            {
                "id": 3,
                "answer": "рутинно"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2
        ]
    },
    {
        "id": 127,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "NURSE_ROLE",
        "question": "Отметьте все правильные утверждения:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "набор для посева крови включает, как минимум, 2 флакона для посева аэробной и анаэробной культур "
            },
            {
                "id": 1,
                "answer": "флаконы для посева культур крови заполняют на ¾ объема"
            },
            {
                "id": 2,
                "answer": "забор крови делают, строго соблюдая асептику"
            },
            {
                "id": 3,
                "answer": "при заборе крови из ранее установленного катетера (не только что введенного), первые несколько мл крови необходимо слить"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            2,
            3
        ]
    },
    {
        "id": 128,
        "topic": "Глава 3. Протокол профилактики катетер-ассоциированной инфекции кровотока",
        "roleTest": "NURSE_ROLE",
        "question": "В течение какого времени после удаления ЦВК следует продолжать наблюдение за местом доступа и менять повязку?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "24 часа"
            },
            {
                "id": 1,
                "answer": "72 часа"
            },
            {
                "id": 2,
                "answer": "7 дней"
            },
            {
                "id": 3,
                "answer": "до момента эпителизации раны"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    }
]