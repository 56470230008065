
export const Questions = [
    {
        "id": 0,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "DOCTOR_ROLE",
        "question": "Отметьте все правильные утверждения:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "ВАП является одной из самых частых инфекций пациентов отделений интенсивной терапии"
            },
            {
                "id": 1,
                "answer": "ВАП является одной из самых частых внутрибольничных инфекций"
            },
            {
                "id": 2,
                "answer": "ВАП повышает риск летального исхода и удлиняет срок госпитализации"
            },
            {
                "id": 3,
                "answer": "Профилактика ВАП основана на использовании антибиотиков"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [0, 2]
    },
    {
        "id": 1,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "DOCTOR_ROLE",
        "question": "Вентилятор-ассоциированное состояние - это:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "ухудшение показателей газообмена на срок не менее 2 дней с последующей стабилизацией или улучшением оксигенации"
            },
            {
                "id": 1,
                "answer": "ухудшение показателей газообмена на срок не менее 3 дней"
            },
            {
                "id": 2,
                "answer": "нейрофункциональное расстройство у пациентов, находящихся на ИВЛ"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [0]
    },
    {
        "id": 2,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "DOCTOR_ROLE",
        "question": "Какие симптомы и признаки обязательны для постановки диагноза ВАП?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "новые, прогрессирующие и персистирующие легочные инфильтраты по данным рентгенологических исследований"
            },
            {
                "id": 1,
                "answer": "лихорадка (> 38°С)"
            },
            {
                "id": 2,
                "answer": "лейкопения (< 4000/мм3)"
            },
            {
                "id": 3,
                "answer": "лейкоцитоз (≥ 12 000/мм3)"
            },
            {
                "id": 4,
                "answer": "появление гнойной мокроты, изменение характера мокроты или увеличение количества трахеобронхиального секрета"
            },
            {
                "id": 5,
                "answer": "ухудшение показателей газообмена, увеличение потребности в кислороде"
            },
            {
                "id": 6,
                "answer": "ни один из симптомов и признаков ВАП не обладает 100%-ной специфичностью"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [6]
    },
    {
        "id": 3,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "DOCTOR_ROLE",
        "question": "ВАП определяется как пневмония, резвившаяся после интубации трахеи и начала ИВЛ не ранее чем:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "через 12 часов"
            },
            {
                "id": 1,
                "answer": "через 24 часа"
            },
            {
                "id": 2,
                "answer": "через 48 часов"
            },
            {
                "id": 3,
                "answer": "через 72 часа"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [2]
    },
    {
        "id": 4,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "DOCTOR_ROLE",
        "question": "Какие из нижеперечисленных факторов не повышают риск развития ВАП?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "возраст старше 65 лет"
            },
            {
                "id": 1,
                "answer": "горизонтальное неподвижное положение пациента"
            },
            {
                "id": 2,
                "answer": "наличие удаленного очага инфекции в организме"
            },
            {
                "id": 3,
                "answer": "хронические болезни легких"
            },
            {
                "id": 4,
                "answer": "перерастяжения желудка, болюсное энтеральное питание"
            },
            {
                "id": 5,
                "answer": "пост-пилорическое энтеральное питание"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [5]
    },
    {
        "id": 5,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "DOCTOR_ROLE",
        "question": "Какие из нижеперечисленных мер неэффективны в профилактике ВАП?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "профилактика перерастяжения желудка и рефлюкса"
            },
            {
                "id": 1,
                "answer": "как можно более ранняя экстубация"
            },
            {
                "id": 2,
                "answer": "обеспечение герметичности дыхательного контура"
            },
            {
                "id": 3,
                "answer": "соблюдение санитарных правил использования дыхательной аппаратуры"
            },
            {
                "id": 4,
                "answer": "промывание эндотрахеальной трубки физраствором"
            },
            {
                "id": 5,
                "answer": "ежедневное прерывание седации и оценка готовности к экстубации"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [4]
    },
    {
        "id": 6,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "DOCTOR_ROLE",
        "question": "Основным источником патогенных микроорганизмов при развитии ВАП является:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "микрофлора ротоглотки и желудка, мигрирующая в легкие"
            },
            {
                "id": 1,
                "answer": "загрязненные руки медперсонала"
            },
            {
                "id": 2,
                "answer": "нестерильные катетеры для аспирации секрета из дыхательных путей"
            },
            {
                "id": 3,
                "answer": "воздух в дыхательном контуре"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [0]
    },
    {
        "id": 7,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "DOCTOR_ROLE",
        "question": "К обязательным мерам профилактики ВАП не относится:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "подъем изголовья койки"
            },
            {
                "id": 1,
                "answer": "регулярная санация ротовой полости"
            },
            {
                "id": 2,
                "answer": "удаление секрета из надманжеточного пространства"
            },
            {
                "id": 3,
                "answer": "системная антибиотикопрофилактика"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [3]
    },
    {
        "id": 8,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "DOCTOR_ROLE",
        "question": "На какой угол рекомендуется поднять изголовье кровати пациента на ИВЛ?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "20 градусов"
            },
            {
                "id": 1,
                "answer": "30 градусов"
            },
            {
                "id": 2,
                "answer": "50 градусов"
            },
            {
                "id": 3,
                "answer": "90 градусов"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 9,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "DOCTOR_ROLE",
        "question": "Оптимальное давление в манжете интубационной трубки составляет:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "10 см вод. ст."
            },
            {
                "id": 1,
                "answer": "20-30 см вод. ст."
            },
            {
                "id": 2,
                "answer": "50 см вод. ст."
            },
            {
                "id": 3,
                "answer": "75 см вод. ст."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 10,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "DOCTOR_ROLE",
        "question": "Чек-лист по уходу за пациентом, находящимся на ИВЛ, необходимо завести:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "сразу после интубации"
            },
            {
                "id": 1,
                "answer": "через 24 часа после интубации"
            },
            {
                "id": 2,
                "answer": "через 48 часов после интубации"
            },
            {
                "id": 3,
                "answer": "после возникновения признаков инфекции дыхательных путей"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 11,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "DOCTOR_ROLE",
        "question": "Кто назначает услугу «Искусственная вентиляция легких» в МИС?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "лечащий врач"
            },
            {
                "id": 1,
                "answer": "реаниматолог"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 12,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "DOCTOR_ROLE",
        "question": "Кто назначает услугу «Уход за респираторным трактом в условиях ИВЛ» в МИС?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "лечащий врач"
            },
            {
                "id": 1,
                "answer": "реаниматолог"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 13,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "DOCTOR_ROLE",
        "question": "При повторной интубации:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "необходимо завести новый чек-лист"
            },
            {
                "id": 1,
                "answer": "ведение чек-листа продолжается с указанием даты повторной интубации"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 14,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "DOCTOR_ROLE",
        "question": "При проведении клинического аудита случаев ВАП оценивается:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "соответствие эмпирической терапии научно обоснованным рекомендациям и инструкциям по медицинскому применению лекарственных препаратов"
            },
            {
                "id": 1,
                "answer": "возможность предотвращения ВАП"
            },
            {
                "id": 2,
                "answer": "влияние ВАП на тяжесть состояния и исходы лечения"
            },
            {
                "id": 3,
                "answer": "учет результатов контроля эффективности эмпирической антибиотикотерапии"
            },
            {
                "id": 4,
                "answer": "соблюдение требований СОП по профилактике ВАП"
            },
            {
                "id": 5,
                "answer": "все вышеперечисленное"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [5]
    },
    {
        "id": 15,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "NURSE_ROLE",
        "question": "Отметьте все правильные утверждения:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "ВАП является одной из самых частых инфекций пациентов отделений интенсивной терапии"
            },
            {
                "id": 1,
                "answer": "ВАП является одной из самых частых внутрибольничных инфекций"
            },
            {
                "id": 2,
                "answer": "ВАП повышает риск летального исхода и удлиняет срок госпитализации"
            },
            {
                "id": 3,
                "answer": "профилактика ВАП основана на использовании антибиотиков"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [0, 2]
    },
    {
        "id": 16,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "NURSE_ROLE",
        "question": "Вентилятор-ассоциированное состояние - это:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "ухудшение показателей газообмена на срок не менее 2 дней с последующей стабилизацией или улучшением оксигенации"
            },
            {
                "id": 1,
                "answer": "ухудшение показателей газообмена на срок не менее 3 дней"
            },
            {
                "id": 2,
                "answer": "нейрофункциональное расстройство у пациентов, находящихся на ИВЛ"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [0]
    },
    {
        "id": 17,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "NURSE_ROLE",
        "question": "Какие из нижеперечисленных факторов НЕ повышают риск развития ВАП?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "возраст старше 65 лет"
            },
            {
                "id": 1,
                "answer": "горизонтальное неподвижное положение пациента"
            },
            {
                "id": 2,
                "answer": "наличие удаленного очага инфекции в организме"
            },
            {
                "id": 3,
                "answer": "хронические болезни легких"
            },
            {
                "id": 4,
                "answer": "перерастяжение желудка, болюсное энтеральное питание"
            },
            {
                "id": 5,
                "answer": "пост-пилорическое энтеральное питание"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [5]
    },
    {
        "id": 18,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "NURSE_ROLE",
        "question": "Какие из нижеперечисленных мер неэффективны в профилактике ВАП?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "профилактика перерастяжения желудка и рефлюкса"
            },
            {
                "id": 1,
                "answer": "как можно более ранняя экстубация"
            },
            {
                "id": 2,
                "answer": "обеспечение герметичности дыхательного контура"
            },
            {
                "id": 3,
                "answer": "соблюдение санитарных правил использования дыхательной аппаратуры"
            },
            {
                "id": 4,
                "answer": "промывание эндотрахеальной трубки физраствором"
            },
            {
                "id": 5,
                "answer": "ежедневное прерывание седации и оценка готовности к экстубации"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [4]
    },
    {
        "id": 19,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "NURSE_ROLE",
        "question": "Основным источником патогенных микроорганизмов при развитии ВАП является:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "микрофлора ротоглотки и желудка, мигрирующая в легкие"
            },
            {
                "id": 1,
                "answer": "загрязненные руки медперсонала"
            },
            {
                "id": 2,
                "answer": "нестерильные катетеры для аспирации секрета из дыхательных путей"
            },
            {
                "id": 3,
                "answer": "воздух в дыхательном контуре"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [0]
    },
    {
        "id": 20,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "NURSE_ROLE",
        "question": "На уменьшение колонизации ротоглотки микроорганизмами направлены следующие профилактические мероприятия:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "подъем изголовья койки "
            },
            {
                "id": 1,
                "answer": "регулярная санация ротовой полости"
            },
            {
                "id": 2,
                "answer": "удаление секрета из надманжеточного пространства"
            },
            {
                "id": 3,
                "answer": "профилактика перерастяжения желудка и рефлюкса"
            },
            {
                "id": 4,
                "answer": "снижение риска случайной экстубации"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [1, 3]
    },
    {
        "id": 21,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "NURSE_ROLE",
        "question": "Какие из следующих мероприятий способствуют снижению риска колонизации эндотрахеальной трубки и контаминации легких:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "подъем изголовья койки"
            },
            {
                "id": 1,
                "answer": "использование закрытой системы для эндотрахеальной санации"
            },
            {
                "id": 2,
                "answer": "обеспечение герметичности дыхательного контура"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [1, 2]
    },
    {
        "id": 22,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "NURSE_ROLE",
        "question": "Подъем изголовья койки способствует:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "уменьшению колонизации ротоглотки"
            },
            {
                "id": 1,
                "answer": "снижению риска аспирации"
            },
            {
                "id": 2,
                "answer": "снижению колонизации эндотрахеальной трубки и контаминации легких"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 23,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "NURSE_ROLE",
        "question": "К обязательным мерам профилактики ВАП не относится:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "подъем изголовья койки"
            },
            {
                "id": 1,
                "answer": "регулярная санация ротовой полости"
            },
            {
                "id": 2,
                "answer": "удаление секрета из надманжеточного пространства"
            },
            {
                "id": 3,
                "answer": "системная антибиотикопрофилактика"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [3]
    },
    {
        "id": 24,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "NURSE_ROLE",
        "question": "На какой угол рекомендуется поднять изголовье кровати пациента на ИВЛ?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "10 градусов"
            },
            {
                "id": 1,
                "answer": "30 градусов"
            },
            {
                "id": 2,
                "answer": "50 градусов"
            },
            {
                "id": 3,
                "answer": "90 градусов"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 25,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "NURSE_ROLE",
        "question": "Оптимальное давление в манжете интубационной трубки составляет:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "10 см вод. ст."
            },
            {
                "id": 1,
                "answer": "20-30 см вод. ст."
            },
            {
                "id": 2,
                "answer": "50 см вод. ст."
            },
            {
                "id": 3,
                "answer": "75 см вод. ст."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 26,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "NURSE_ROLE",
        "question": "Чек-лист по уходу за пациентом, находящимся на ИВЛ, необходимо завести:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "сразу после интубации"
            },
            {
                "id": 1,
                "answer": "через 24 часа после интубации"
            },
            {
                "id": 2,
                "answer": "через 48 часов после интубации"
            },
            {
                "id": 3,
                "answer": "после возникновения признаков инфекции дыхательных путей"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 27,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "NURSE_ROLE",
        "question": "С какой частотой следует обрабатывать полость рта пациента на ИВЛ?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "каждый час"
            },
            {
                "id": 1,
                "answer": "каждые 2 часа"
            },
            {
                "id": 2,
                "answer": "каждые 6-8 часов"
            },
            {
                "id": 3,
                "answer": "один раз в сутки"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [2]
    },
    {
        "id": 28,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "NURSE_ROLE",
        "question": "Как часто следует менять дыхательный контур для пациента на ИВЛ?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "каждый день"
            },
            {
                "id": 1,
                "answer": "один раз в неделю"
            },
            {
                "id": 2,
                "answer": "каждые 2 недели"
            },
            {
                "id": 3,
                "answer": "при необходимости"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [3]
    },
    {
        "id": 29,
        "topic": "Глава 1. Протокол профилактики вентилятор-ассоциированной пневмонии",
        "roleTest": "NURSE_ROLE",
        "question": "Как часто следует смещать эндотрахеальную трубку вдоль оси трахеи?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "2 раза в день"
            },
            {
                "id": 1,
                "answer": "один раз в день"
            },
            {
                "id": 2,
                "answer": "один раз в неделю"
            },
            {
                "id": 3,
                "answer": "при необходимости"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 30,
        "topic": "Глава 2. Протокол обеспечения хирургической безопасности",
        "roleTest": "NO_ROLE",
        "question": "Цели СОП «Хирургическая безопасность» включают:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "уменьшение числа предотвратимых неблагоприятных событий (осложнений), связанных с хирургическими вмешательствами"
            },
            {
                "id": 1,
                "answer": "улучшение преемственности между подразделениями и медицинскими работниками, участвующими в оказании хирургической помощи"
            },
            {
                "id": 2,
                "answer": "предотвращение развития критических событий, связанных с хирургическими вмешательствами"
            },
            {
                "id": 3,
                "answer": "внедрение международных стандартов хирургической безопасности"
            },
            {
                "id": 4,
                "answer": "уменьшение финансовых затрат на хирургическую помощь за счёт оптимизации процессов и эффективной профилактики хирургических осложнений"
            },
            {
                "id": 5,
                "answer": "все ответы правильные"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [5]
    },
    {
        "id": 31,
        "topic": "Глава 2. Протокол обеспечения хирургической безопасности",
        "roleTest": "NO_ROLE",
        "question": "Для профилактики хирургических осложнений на амбулаторном этапе нужно:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "определить показания и противопоказания к операции"
            },
            {
                "id": 1,
                "answer": "уточнить диагноз и провести обследование перед операцией"
            },
            {
                "id": 2,
                "answer": "провести оценку сердечно-сосудистых рисков предстоящей операции"
            },
            {
                "id": 3,
                "answer": "провести оценку анестезиологических рисков предстоящей операции"
            },
            {
                "id": 4,
                "answer": "оформить список постоянно принимаемых пациентом лекарств"
            },
            {
                "id": 5,
                "answer": "оформить эпикриз на госпитализацию"
            },
            {
                "id": 6,
                "answer": "все ответы правильные"
            },
            {
                "id": 7,
                "answer": "все ответы неправильные"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [6]
    },
    {
        "id": 32,
        "topic": "Глава 2. Протокол обеспечения хирургической безопасности",
        "roleTest": "NO_ROLE",
        "question": "Для профилактики хирургических осложнений на стационарном этапе нужно:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "проводить идентификацию личности пациента на всех этапах; убедиться, что медицинская документация и результаты обследований соответствуют пациенту"
            },
            {
                "id": 1,
                "answer": "подтвердить наличие показаний и отсутствие противопоказаний для оперативного вмешательства; Провести сверку получаемых пациентом лекарственных препаратов"
            },
            {
                "id": 2,
                "answer": "получить информированное добровольное согласие пациента на операцию и анестезию"
            },
            {
                "id": 3,
                "answer": "планировать дату операции с учетом необходимого оборудования и ресурсов"
            },
            {
                "id": 4,
                "answer": "выполнить маркировку зоны операции и рациональную ПАП (если требуется)"
            },
            {
                "id": 5,
                "answer": "выполнить требования ВОЗ в операционной с обязательным тайм-аутом перед началом операции"
            },
            {
                "id": 6,
                "answer": "все ответы правильные"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [6]
    },
    {
        "id": 33,
        "topic": "Глава 2. Протокол обеспечения хирургической безопасности",
        "roleTest": "NO_ROLE",
        "question": "Временные противопоказания к плановым оперативным вмешательствам включают все, кроме:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "возраста пациента старше 70 лет"
            },
            {
                "id": 1,
                "answer": "пациентов с дефицитом самоухода"
            },
            {
                "id": 2,
                "answer": "острой сердечной недостаточностью"
            },
            {
                "id": 3,
                "answer": "инфаркта миокарда, нестабильной стенокардии в последние 30 дней"
            },
            {
                "id": 4,
                "answer": "баллонную ангиопластику в последние 2 недели"
            },
            {
                "id": 5,
                "answer": "аортокоронарное (маммарокоронарное) шунтирование в последние 6 недель"
            },
            {
                "id": 6,
                "answer": "установку сосудистого стента в последние 3-12 месяцев"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [0, 1]
    },
    {
        "id": 34,
        "topic": "Глава 2. Протокол обеспечения хирургической безопасности",
        "roleTest": "NO_ROLE",
        "question": "Контрольный перечень ВОЗ – это:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "перечень мероприятий, необходимых для снижения риска осложнений и смерти в результате хирургических вмешательств"
            },
            {
                "id": 1,
                "answer": "перечень осложнений хирургических вмешательств, подлежащих статистическому учету"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [0]
    },
    {
        "id": 35,
        "topic": "Глава 2. Протокол обеспечения хирургической безопасности",
        "roleTest": "NO_ROLE",
        "question": "Хирургическая бригада включает:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "только хирургов"
            },
            {
                "id": 1,
                "answer": "хирургов и анестезиолога"
            },
            {
                "id": 2,
                "answer": "хирургов, анестезиологов и медицинских сестер"
            },
            {
                "id": 3,
                "answer": "хирургов, анестезиологов, медсестер, техников и другой персонал, принимающий участие в операции"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [3]
    },
    {
        "id": 36,
        "topic": "Глава 2. Протокол обеспечения хирургической безопасности",
        "roleTest": "NO_ROLE",
        "question": "Критическое событие – это:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "повторная госпитализация пациента в период 30 дней после операции"
            },
            {
                "id": 1,
                "answer": "отмена плановой госпитализации пациента"
            },
            {
                "id": 2,
                "answer": "отмена плановой операции"
            },
            {
                "id": 3,
                "answer": "внезапная смерть или значительная утрата функции, не связанные с заболеванием пациента или его общим состоянием"
            },
            {
                "id": 4,
                "answer": "выполнение ошибочной операции, на ошибочной части тела, ошибочному пациенту"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [3, 4]
    },
    {
        "id": 37,
        "topic": "Глава 2. Протокол обеспечения хирургической безопасности",
        "roleTest": "NO_ROLE",
        "question": "При выявлении критического события необходимо:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "устранить, по-возможности, обстоятельства, иначе – отменить операцию"
            },
            {
                "id": 1,
                "answer": "перевести пациента в ОРИТ"
            },
            {
                "id": 2,
                "answer": "доложить заместителю главного врача по лечебной работе"
            },
            {
                "id": 3,
                "answer": "провести аудит оказанной помощи, разобрать на ЛКП, составить план действий по профилактике критических событий"
            },
            {
                "id": 4,
                "answer": "все ответы верные"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [4]
    },
    {
        "id": 38,
        "topic": "Глава 2. Протокол обеспечения хирургической безопасности",
        "roleTest": "NO_ROLE",
        "question": "Тайм–аут (пауза) – это:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "перерыв между операциями"
            },
            {
                "id": 1,
                "answer": "пауза, когда пациент должен подписать информированное добровольное согласие на операцию"
            },
            {
                "id": 2,
                "answer": "пауза непосредственно перед выполнением хирургической операции, во время которой любые оставшиеся без ответа вопросы или неясности о пациенте или операции должны быть разрешены"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [2]
    },
    {
        "id": 39,
        "topic": "Глава 2. Протокол обеспечения хирургической безопасности",
        "roleTest": "NO_ROLE",
        "question": "Внутрибольничная хирургическая инфекция – это:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "инфекционные заболевания в хирургических отделениях"
            },
            {
                "id": 1,
                "answer": "инфекция в зоне хирургического вмешательства в течение 30 дней после операции"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 40,
        "topic": "Глава 2. Протокол обеспечения хирургической безопасности",
        "roleTest": "NO_ROLE",
        "question": "К послеоперационным осложнениям относится все, кроме:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "оставления инородных тел"
            },
            {
                "id": 1,
                "answer": "кровотечения/гематом"
            },
            {
                "id": 2,
                "answer": "послеоперационного сепсиса"
            },
            {
                "id": 3,
                "answer": "расхождения краев послеоперационной раны"
            },
            {
                "id": 4,
                "answer": "непреднамеренного ранения или перфорации органа"
            },
            {
                "id": 5,
                "answer": "несостоятельности швов анастомоза или отторжение трансплантата"
            },
            {
                "id": 6,
                "answer": "инфекции области хирургического вмешательства"
            },
            {
                "id": 7,
                "answer": "повторной госпитализации в течение 30 дней после операции"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [7]
    },
    {
        "id": 41,
        "topic": "Глава 2. Протокол обеспечения хирургической безопасности",
        "roleTest": "NO_ROLE",
        "question": "Требования к маркировке зоны операции НЕ включают следующего:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "маркировка места операции выполняется везде, где возможно"
            },
            {
                "id": 1,
                "answer": "маркировка выполняется лицом, выполняющим операцию, или другим сотрудником, участвующим в операции;"
            },
            {
                "id": 2,
                "answer": "пациент участвовал в маркировке (если применимо);"
            },
            {
                "id": 3,
                "answer": "маркировка не должна стираться во время обработки поля"
            },
            {
                "id": 4,
                "answer": "маркировка должна быть одинаковой во всех отделениях"
            },
            {
                "id": 5,
                "answer": "место будущей операции лучше всего маркировать не линией, а ставить крестик"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [5]
    },
    {
        "id": 42,
        "topic": "Глава 2. Протокол обеспечения хирургической безопасности",
        "roleTest": "NO_ROLE",
        "question": "Основными целями чек-листа ВОЗ до начала анестезии являются:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "идентификация пациента, проверка правильности медицинской документации и наличия информированного согласия пациента"
            },
            {
                "id": 1,
                "answer": "оценка трудностей интубации"
            },
            {
                "id": 2,
                "answer": "проверка наличия необходимого оборудования и имплантов"
            },
            {
                "id": 3,
                "answer": "уточнение риска большой кровопотери"
            },
            {
                "id": 4,
                "answer": "все ответы правильные"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [4]
    },
    {
        "id": 43,
        "topic": "Глава 2. Протокол обеспечения хирургической безопасности",
        "roleTest": "NO_ROLE",
        "question": "Основными целями тайм-аута являются:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "скорейший переход к началу операции"
            },
            {
                "id": 1,
                "answer": "возможность провести текущую уборку в операционной"
            },
            {
                "id": 2,
                "answer": "повторное подтверждение ФИО пациента, объема и места операции; оценка возможных критических событий хирургом и анестезиологом"
            },
            {
                "id": 3,
                "answer": "подтверждение выполненной АБ-профилактики; указание что отсутствуют какие-либо проблемы и расхождения, мешающие начать операцию"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [2, 3]
    },
    {
        "id": 44,
        "topic": "Глава 2. Протокол обеспечения хирургической безопасности",
        "roleTest": "NO_ROLE",
        "question": "Основными целями чек-листа ВОЗ до ушивания раны (до того, как пациент покинет операционную) являются:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "подтверждение хирургом названия и объема выполненной операции"
            },
            {
                "id": 1,
                "answer": "подтверждение медицинской сестрой соответствие тампонов, игл, инструментов"
            },
            {
                "id": 2,
                "answer": "подтверждение правильной маркировки биоматериалов"
            },
            {
                "id": 3,
                "answer": "совместная (хирург и анестезиолог) оценка ведения раннего послеоперационного периода"
            },
            {
                "id": 4,
                "answer": "все ответы верны"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [4]
    },
    {
        "id": 45,
        "topic": "Глава 3. Протокол применения антикоагулянтов в стационаре",
        "roleTest": "NO_ROLE",
        "question": "Какие из перечисленных сведений лечащий врач должен документировать в дневниковой записи в первый день назначения антикоагулянта в соответствии с действующим регламентом применения антикоагулянтов?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "показание к назначению"
            },
            {
                "id": 1,
                "answer": "время последнего приема антикоагулянта (если пациент принимал антикоагулянт ранее)"
            },
            {
                "id": 2,
                "answer": "количество эритроцитов, тромбоцитов, уровень гемоглобина"
            },
            {
                "id": 3,
                "answer": "риск кровотечения"
            },
            {
                "id": 4,
                "answer": "клиренс креатинина"
            },
            {
                "id": 5,
                "answer": "противопоказания (при наличии)"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [0, 1, 3, 4, 5]
    },
    {
        "id": 46,
        "topic": "Глава 3. Протокол применения антикоагулянтов в стационаре",
        "roleTest": "NO_ROLE",
        "question": "Выберите все прямые оральные антикоагулянты",
        "responseOptions": [
            {
                "id": 0,
                "answer": "далтепарин (Фрагмин)"
            },
            {
                "id": 1,
                "answer": "дабигатран (Прадакса)"
            },
            {
                "id": 2,
                "answer": "эноксапарин (Клексан)"
            },
            {
                "id": 3,
                "answer": "апиксабан (Эликвис)"
            },
            {
                "id": 4,
                "answer": "варфарин"
            },
            {
                "id": 5,
                "answer": "эноксапарин (Клексан)"
            },
            {
                "id": 6,
                "answer": "клопидогрел (Плавикс)"
            },
            {
                "id": 7,
                "answer": "ривароксабан (Ксарелто)"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [1, 3, 7]
    },
    {
        "id": 47,
        "topic": "Глава 3. Протокол применения антикоагулянтов в стационаре",
        "roleTest": "NO_ROLE",
        "question": "Выберите оральные антикоагулянты непрямого действия",
        "responseOptions": [
            {
                "id": 0,
                "answer": "дабигатран (Прадакса)"
            },
            {
                "id": 1,
                "answer": "эноксапарин (Клексан)"
            },
            {
                "id": 2,
                "answer": "апиксабан (Эликвис)"
            },
            {
                "id": 3,
                "answer": "варфарин"
            },
            {
                "id": 4,
                "answer": "эноксапарин (Клексан)"
            },
            {
                "id": 5,
                "answer": "клопидогрел (Плавикс)"
            },
            {
                "id": 6,
                "answer": "ривароксабан (Ксарелто)"
            },
            {
                "id": 7,
                "answer": "далтепарин (Фрагмин)"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [3]
    },
    {
        "id": 48,
        "topic": "Глава 3. Протокол применения антикоагулянтов в стационаре",
        "roleTest": "NO_ROLE",
        "question": "Выберите лабораторные исследования, входящих в необходимый минимум перед назначением антикоагулянтов",
        "responseOptions": [
            {
                "id": 0,
                "answer": "анализ крови на наличие HIT-антител"
            },
            {
                "id": 1,
                "answer": "общий (клинический) анализ крови"
            },
            {
                "id": 2,
                "answer": "биохимический анализ крови (АЛТ, АСТ, билирубин, креатинин)"
            },
            {
                "id": 3,
                "answer": "коагулограмма"
            },
            {
                "id": 4,
                "answer": "анализ анти-Ха активности плазмы"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [1, 2, 3]
    },
    {
        "id": 49,
        "topic": "Глава 3. Протокол применения антикоагулянтов в стационаре",
        "roleTest": "NO_ROLE",
        "question": "Как часто нужно рассчитывать клиренс креатинина при применении варфарина для вторичной профилактики тромбоза глубоких вен?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "1 раз в 7 дней"
            },
            {
                "id": 1,
                "answer": "1 раз в месяц"
            },
            {
                "id": 2,
                "answer": "1 раз в 3 мес"
            },
            {
                "id": 3,
                "answer": "регулярный контроль клиренса креатинина не требуется"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [3]
    },
    {
        "id": 50,
        "topic": "Глава 3. Протокол применения антикоагулянтов в стационаре",
        "roleTest": "NO_ROLE",
        "question": "Как часто нужно измерять уровень сывороточного креатинина и рассчитывать клиренс креатинина у пациента с постоянной формой фибриллции предсердий и клиренсом креатинина 44 мл/мин, получающего ривароксабан (Ксарелто) 15 мг 1 раз в сутки?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "1 раз в месяц"
            },
            {
                "id": 1,
                "answer": "1 раза в 3 мес"
            },
            {
                "id": 2,
                "answer": "1 раза в 4 мес"
            },
            {
                "id": 3,
                "answer": "1 раз в 6 мес"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [2]
    },
    {
        "id": 51,
        "topic": "Глава 3. Протокол применения антикоагулянтов в стационаре",
        "roleTest": "NO_ROLE",
        "question": "Какую информацию о назначении антикоагулянта следует указать в листе назначений?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "АТХ-код"
            },
            {
                "id": 1,
                "answer": "торговое наименование"
            },
            {
                "id": 2,
                "answer": "срок годности"
            },
            {
                "id": 3,
                "answer": "разовая доза"
            },
            {
                "id": 4,
                "answer": "форма выпуска"
            },
            {
                "id": 5,
                "answer": "кратность применения;"
            },
            {
                "id": 6,
                "answer": "путь введения."
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [1, 3, 5, 6]
    },
    {
        "id": 52,
        "topic": "Глава 3. Протокол применения антикоагулянтов в стационаре",
        "roleTest": "NO_ROLE",
        "question": "Как часто следует контролировать уровень тромбоцитов в крови при применении низкомолекулярных гепаринов?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "через день"
            },
            {
                "id": 1,
                "answer": "1 раз в 7 дней"
            },
            {
                "id": 2,
                "answer": "1 раз в 10 дней"
            },
            {
                "id": 3,
                "answer": "регулярный контроль не требуется"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 53,
        "topic": "Глава 3. Протокол применения антикоагулянтов в стационаре",
        "roleTest": "NO_ROLE",
        "question": "Рекомендуемое время приема варфарина в соответствии с СОП применения антикоагулянтов.",
        "responseOptions": [
            {
                "id": 0,
                "answer": "до 10:00 во время приема пищи"
            },
            {
                "id": 1,
                "answer": "с 12:00 до 13:00"
            },
            {
                "id": 2,
                "answer": "с 17:00 до 18:00"
            },
            {
                "id": 3,
                "answer": "перед сном"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [2]
    },
    {
        "id": 54,
        "topic": "Глава 3. Протокол применения антикоагулянтов в стационаре",
        "roleTest": "NO_ROLE",
        "question": "В каком документе истории болезни необходимо указать терапевтический диапазон МНО при применении варфарина в соответствии с протоколом применения антикоагулянтов?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "в листе назначений"
            },
            {
                "id": 1,
                "answer": "в дневнике наблюдения в первый день назначения варфарина"
            },
            {
                "id": 2,
                "answer": "в выписном эпикризе в списке рекомендованной лекарственной терапии"
            },
            {
                "id": 3,
                "answer": "в этапном эпикризе"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [1, 2]
    },
    {
        "id": 55,
        "topic": "Глава 3. Протокол применения антикоагулянтов в стационаре",
        "roleTest": "NO_ROLE",
        "question": "Укажите допустимые концентрации раствора НФГ для применения в виде непрерывной в/в инфузии в соответствии с протоколом применения антикоагулянтов.",
        "responseOptions": [
            {
                "id": 0,
                "answer": "можно использовать любые концентрации раствора НФГ при условии обеспечения коррекции скорости инфузии по протоколу"
            },
            {
                "id": 1,
                "answer": "только 50 000 МЕ/50 мл"
            },
            {
                "id": 2,
                "answer": "только 25000 МЕ/50 мл"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 56,
        "topic": "Глава 3. Протокол применения антикоагулянтов в стационаре",
        "roleTest": "NO_ROLE",
        "question": "Какую информацию о назначенном антикоагулянте следует указать в выписном эпикризе в списке рекомендованной лекарственной терапии?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "название препарата"
            },
            {
                "id": 1,
                "answer": "разовую дозу"
            },
            {
                "id": 2,
                "answer": "суточную дозу"
            },
            {
                "id": 3,
                "answer": "недельную дозу"
            },
            {
                "id": 4,
                "answer": "длительность применения"
            },
            {
                "id": 5,
                "answer": "кратность применения"
            },
            {
                "id": 6,
                "answer": "фармацевтическую компанию-производитель лекарственного средства"
            },
            {
                "id": 7,
                "answer": "путь введения"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [0, 1, 4, 5, 7]
    },
    {
        "id": 57,
        "topic": "Глава 3. Протокол применения антикоагулянтов в стационаре",
        "roleTest": "NO_ROLE",
        "question": "Выберите недопустимые дозы антикоагулянтов в соответствии с инструкциями по медицинскому применению при применении для профилактики ишемического инсульта и системных эмболий у пациентов с фибрилляцией/трепетанием предсердий:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "дабигатран 150 мг 2 раза в сутки"
            },
            {
                "id": 1,
                "answer": "дабигатран 300 мг 1 раз в день"
            },
            {
                "id": 2,
                "answer": "ривароксабан 20 мг 2 раза в сутки"
            },
            {
                "id": 3,
                "answer": "ривароксабан 15 мг 1 раз в сутки"
            },
            {
                "id": 4,
                "answer": "апиксабан 2,5 мг 2 раза в сутки"
            },
            {
                "id": 5,
                "answer": "апиксабан 5 мг 1 раз в сутки"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [1, 2, 5]
    },
    {
        "id": 58,
        "topic": "Глава 3. Протокол применения антикоагулянтов в стационаре",
        "roleTest": "NO_ROLE",
        "question": "Какие дозы антикоагулянтов недопустимы для лечения и профилактики тромбоза глубоких вен и/или тромбоэмболии легочной артерии в соответствии с инструкциями по медицинскому применению:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "дабигатран 150 мг 2 раза в сутки"
            },
            {
                "id": 1,
                "answer": "дабигатран 300 мг 1 раз в сутки"
            },
            {
                "id": 2,
                "answer": "дабигатран 110 мг 2 раза в сутки"
            },
            {
                "id": 3,
                "answer": "ривароксабан 15 мг 2 раза в сутки в течение 21 дня, затем по 20 мг 1 раз в сутки"
            },
            {
                "id": 4,
                "answer": "ривароксабан 15 мг 1 раз в сутки"
            },
            {
                "id": 5,
                "answer": "апиксабан 5 мг 1 раз в сутки"
            },
            {
                "id": 6,
                "answer": "апиксабан 5 мг 2 раза в сутки"
            },
            {
                "id": 7,
                "answer": "апиксабан 10 мг 2 раза в сутки в течение 7 дней, затем по 5 мг 2 раза в сутки"
            },
            {
                "id": 8,
                "answer": "апиксабан 2,5 мг 2 раза в сутки"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [1, 2, 4, 5, 8]
    },
    {
        "id": 59,
        "topic": "Глава 4. Порядок идентификации пациентов при оказании медицинской помощи",
        "roleTest": "NO_ROLE",
        "question": "Сколько идентификаторов используется для устной идентификации личности пациента в стационаре?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "1"
            },
            {
                "id": 1,
                "answer": "2"
            },
            {
                "id": 2,
                "answer": "3"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 60,
        "topic": "Глава 4. Порядок идентификации пациентов при оказании медицинской помощи",
        "roleTest": "NO_ROLE",
        "question": "Какие стандартные идентификаторы используются для идентификации личности пациента в медицинской документации и устно?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "фамилия, имя отчество пациента полностью"
            },
            {
                "id": 1,
                "answer": "фамилия и инициалы пациента"
            },
            {
                "id": 2,
                "answer": "число, месяц и год рождения"
            },
            {
                "id": 3,
                "answer": "возраст пациента"
            },
            {
                "id": 4,
                "answer": "номер палаты"
            },
            {
                "id": 5,
                "answer": "отделение, в которое госпитализирован пациент"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [0, 2]
    },
    {
        "id": 61,
        "topic": "Глава 4. Порядок идентификации пациентов при оказании медицинской помощи",
        "roleTest": "NO_ROLE",
        "question": "Существуют ли исключения для использования стандартных идентификаторов личности пациента?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "да"
            },
            {
                "id": 1,
                "answer": "нет"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [0]
    },
    {
        "id": 62,
        "topic": "Глава 4. Порядок идентификации пациентов при оказании медицинской помощи",
        "roleTest": "NO_ROLE",
        "question": "Какие идентификаторы указываются на пробирках для крови, контейнерах для сбора мочи, мокроты, кала?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "фамилия, имя отчество пациента полностью"
            },
            {
                "id": 1,
                "answer": "фамилия и инициалы пациента"
            },
            {
                "id": 2,
                "answer": "число, месяц и год рождения"
            },
            {
                "id": 3,
                "answer": "возраст пациента"
            },
            {
                "id": 4,
                "answer": "номер палаты"
            },
            {
                "id": 5,
                "answer": "номер истории болезни /амбулаторной карты"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [1, 2, 5]
    },
    {
        "id": 63,
        "topic": "Глава 4. Порядок идентификации пациентов при оказании медицинской помощи",
        "roleTest": "NO_ROLE",
        "question": "В каких из перечисленных ниже случаев проводится идентификация пациента?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "перед выполнением любой инвазивной процедуры"
            },
            {
                "id": 1,
                "answer": "перед проведением диагностических мероприятий"
            },
            {
                "id": 2,
                "answer": "при первом контакте с пациентом в течение одного эпизода оказания медицинской помощи"
            },
            {
                "id": 3,
                "answer": "при каждом контакте с пациентом"
            },
            {
                "id": 4,
                "answer": "при переводе из одного отделения в другое"
            },
            {
                "id": 5,
                "answer": "перед применением медикаментов и химиотерапии"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [0, 1, 2, 4, 5]
    },
    {
        "id": 64,
        "topic": "Глава 4. Порядок идентификации пациентов при оказании медицинской помощи",
        "roleTest": "NO_ROLE",
        "question": "Что означает активный метод установления личности пациента?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "медработник называет фамилию, имя, отчество пациента и просит подтвердить эту информацию"
            },
            {
                "id": 1,
                "answer": "медработник просит пациента назвать фамилию, имя, отчество и дату рождения"
            },
            {
                "id": 2,
                "answer": "медработник активно сверяет паспортные данные пациента с медицинской документацией"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 65,
        "topic": "Глава 4. Порядок идентификации пациентов при оказании медицинской помощи",
        "roleTest": "NO_ROLE",
        "question": "Если пациент спит, то перед проведением медицинской манипуляции:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "процедура идентификации не проводится"
            },
            {
                "id": 1,
                "answer": "пациента следует разбудить и установить личность в соответствии с правилами"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 66,
        "topic": "Глава 4. Порядок идентификации пациентов при оказании медицинской помощи",
        "roleTest": "NO_ROLE",
        "question": "Какие методы идентификации могут использоваться для пациента с нарушением сознания или затрудненной коммуникацией?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "бейдж"
            },
            {
                "id": 1,
                "answer": "браслет"
            },
            {
                "id": 2,
                "answer": "маркировка кожи руки несмываемым маркером"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [0, 1]
    },
    {
        "id": 67,
        "topic": "Глава 4. Порядок идентификации пациентов при оказании медицинской помощи",
        "roleTest": "NO_ROLE",
        "question": "Решение о необходимости идентификации пациента дополнительными методами на этапе госпитализации принимает:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "медсестра приемного отделения"
            },
            {
                "id": 1,
                "answer": "врач приемного отделения"
            },
            {
                "id": 2,
                "answer": "невролог после проведения консультации"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 68,
        "topic": "Глава 4. Порядок идентификации пациентов при оказании медицинской помощи",
        "roleTest": "NO_ROLE",
        "question": "Какую руку лучше использовать для идентификационного приспособления?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "доминантную"
            },
            {
                "id": 1,
                "answer": "не доминантную"
            },
            {
                "id": 2,
                "answer": "любую"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [0]
    },
    {
        "id": 69,
        "topic": "Глава 4. Порядок идентификации пациентов при оказании медицинской помощи",
        "roleTest": "NO_ROLE",
        "question": "Какие данные указываются на идентификационном приспособлении?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "ФИО пациента полностью"
            },
            {
                "id": 1,
                "answer": "возраст"
            },
            {
                "id": 2,
                "answer": "дата рождения в формате «чч.мм.гггг»"
            },
            {
                "id": 3,
                "answer": "дата рождения в формате «чч.мм.гг»"
            },
            {
                "id": 4,
                "answer": "номер истории болезни"
            },
            {
                "id": 5,
                "answer": "номер палаты и отделение"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [0, 2]
    },
    {
        "id": 70,
        "topic": "Глава 4. Порядок идентификации пациентов при оказании медицинской помощи",
        "roleTest": "NO_ROLE",
        "question": "Какие данные указываются на идентификационном приспособлении пациента, чья личность неизвестна?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "«неизвестный мужчина /женщина»"
            },
            {
                "id": 1,
                "answer": "примерный возраст"
            },
            {
                "id": 2,
                "answer": "дата поступления в стационар"
            },
            {
                "id": 3,
                "answer": "номер истории болезни"
            },
            {
                "id": 4,
                "answer": "номер палаты и отделение"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [0, 2]
    },
    {
        "id": 71,
        "topic": "Глава 4. Порядок идентификации пациентов при оказании медицинской помощи",
        "roleTest": "NO_ROLE",
        "question": "Ошибки идентификации пациента регистрируют:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "старшие медицинские сестры в свободной форме"
            },
            {
                "id": 1,
                "answer": "старшие медицинские сестры по единой форме рапорта"
            },
            {
                "id": 2,
                "answer": "заведующий отделением в годовом отчете о работе отделения"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 72,
        "topic": "Глава 4. Порядок идентификации пациентов при оказании медицинской помощи",
        "roleTest": "NO_ROLE",
        "question": "Рапорт об ошибке идентификации:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "вкладывается в историю болезни"
            },
            {
                "id": 1,
                "answer": "не является частью истории болезни"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [1]
    },
    {
        "id": 73,
        "topic": "Глава 4. Порядок идентификации пациентов при оказании медицинской помощи",
        "roleTest": "NO_ROLE",
        "question": "При поступлении не идентифицированного пациента медсестра приемного отделения:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "немедленно сообщает об этом главному врачу"
            },
            {
                "id": 1,
                "answer": "сообщает об этом в органы внутренних дел в течение суток"
            },
            {
                "id": 2,
                "answer": "составляет рапорт и сообщает об этом главной медсестре"
            },
            {
                "id": 3,
                "answer": "сообщает об этом в органы внутренних дел в течение двух часов"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [3]
    }
]