export const options = {
  plugins: {
    legend: {
      display: false,
    }
  },
  scales: {
    r: {
      angleLines: {
        display: false
      },
      suggestedMin: 0,
      suggestedMax: 10
    }
  },
  elements: {
    line: {
      borderWidth: 7
    }
  }
};