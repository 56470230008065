
export const Questions = [
    {
        "id": 0,
        "topic": "Глава 1. Стратегии обеспечения безопасности пациентов и управление клиническими рисками",
        "roleTest": "NO_ROLE",
        "question": "По определению Всемирной организации здравоохранения, безопасность пациента – это:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "отсутствие вреда пациенту в процессе оказания медицинской помощи"
            },
            {
                "id": 1,
                "answer": "отсутствие предотвратимого вреда пациенту в процессе оказания медицинской помощи и снижение до приемлемого минимума риска излишнего вреда"
            },
            {
                "id": 2,
                "answer": "нейтрализация неблагоприятных исходов оказания медицинской помощи"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 1,
        "topic": "Глава 1. Стратегии обеспечения безопасности пациентов и управление клиническими рисками",
        "roleTest": "NO_ROLE",
        "question": "В каком году Всемирная ассамблея здравоохранения приняла первую резолюцию по безопасности пациентов?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "в 1999 г."
            },
            {
                "id": 1,
                "answer": "в 2002 г."
            },
            {
                "id": 2,
                "answer": "в 2010 г."
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 2,
        "topic": "Глава 1. Стратегии обеспечения безопасности пациентов и управление клиническими рисками",
        "roleTest": "NO_ROLE",
        "question": "Назовите дату Всемирного дня безопасности пациентов",
        "responseOptions": [
            {
                "id": 0,
                "answer": "20 апреля"
            },
            {
                "id": 1,
                "answer": "первое воскресенье марта"
            },
            {
                "id": 2,
                "answer": "17 сентября"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 3,
        "topic": "Глава 1. Стратегии обеспечения безопасности пациентов и управление клиническими рисками",
        "roleTest": "NO_ROLE",
        "question": "Предотвратимое неблагоприятное событие – это:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "осложнение, резвившееся вследствие оказания медицинской помощи"
            },
            {
                "id": 1,
                "answer": "внутрибольничное осложнение, развившееся в отсутствие профилактики "
            },
            {
                "id": 2,
                "answer": "событие, явившееся следствием ненадлежащего выполнения общепризнанных, научно обоснованных методов оказания медицинской помощи или клинических рекомендаций"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 4,
        "topic": "Глава 1. Стратегии обеспечения безопасности пациентов и управление клиническими рисками",
        "roleTest": "NO_ROLE",
        "question": "В каком нормативном документе содержится определение безопасности медицинской помощи?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "в Федеральном законе об основах охраны здоровья граждан Российской Федерации"
            },
            {
                "id": 1,
                "answer": "в Федеральном законе о защите прав потребителей"
            },
            {
                "id": 2,
                "answer": "в Постановлении Правительства РФ о государственном контроле качества и безопасности медицинской деятельности"
            },
            {
                "id": 3,
                "answer": "в рекомендациях Федеральной службы по надзору в сфере здравоохранения по организации внутреннего контроля качества и безопасности медицинской деятельности"
            },
            {
                "id": 4,
                "answer": "ни в одном из утвержденных на сегодняшний день нормативных документов федерального уровня"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            4
        ]
    },
    {
        "id": 5,
        "topic": "Глава 1. Стратегии обеспечения безопасности пациентов и управление клиническими рисками",
        "roleTest": "NO_ROLE",
        "question": "Какие параметры учитываются в матрице рисков?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "вероятность"
            },
            {
                "id": 1,
                "answer": "величина ущерба"
            },
            {
                "id": 2,
                "answer": "категория риска"
            },
            {
                "id": 3,
                "answer": "владелец риска"
            },
            {
                "id": 4,
                "answer": "функция риска"
            },
            {
                "id": 5,
                "answer": "количество рисков"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            2,
            3
        ]
    },
    {
        "id": 6,
        "topic": "Глава 1. Стратегии обеспечения безопасности пациентов и управление клиническими рисками",
        "roleTest": "NO_ROLE",
        "question": "Какие существуют способы воздействия на клинический риск?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "снижение риска"
            },
            {
                "id": 1,
                "answer": "сохранение риска"
            },
            {
                "id": 2,
                "answer": "передача риска"
            },
            {
                "id": 3,
                "answer": "отказ от риска"
            },
            {
                "id": 4,
                "answer": "умножение риска"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2,
            3
        ]
    },
    {
        "id": 7,
        "topic": "Глава 1. Стратегии обеспечения безопасности пациентов и управление клиническими рисками",
        "roleTest": "NO_ROLE",
        "question": "Каким из способов воздействия на риск является получение информированного согласия пациента на оказание медицинской услуги?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "снижение риска"
            },
            {
                "id": 1,
                "answer": "сохранение риска"
            },
            {
                "id": 2,
                "answer": "передача риска"
            },
            {
                "id": 3,
                "answer": "отказ от риска"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 8,
        "topic": "Глава 1. Стратегии обеспечения безопасности пациентов и управление клиническими рисками",
        "roleTest": "NO_ROLE",
        "question": "Что такое триггер внутрибольничного осложнения согласно методике «Глобальной оценки триггеров»?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "это инцидент, по которому можно заподозрить развитие нежелательного события"
            },
            {
                "id": 1,
                "answer": "это инцидент, свидетельствующий о развитии нежелательного события"
            },
            {
                "id": 2,
                "answer": "это метод предотвращения нежелательного события до его развития"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 9,
        "topic": "Глава 1. Стратегии обеспечения безопасности пациентов и управление клиническими рисками",
        "roleTest": "NO_ROLE",
        "question": "В каких из перечисленных случаев используется инцидентный анализ?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "инциденты, связанные с преступными действиями"
            },
            {
                "id": 1,
                "answer": "инциденты, связанные с токсикоманией персонала"
            },
            {
                "id": 2,
                "answer": "преднамеренные действия с целью причинения вреда, жестоким обращением с пациентом"
            },
            {
                "id": 3,
                "answer": "суицидальная попытка пациента"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    },
    {
        "id": 10,
        "topic": "Глава 1. Стратегии обеспечения безопасности пациентов и управление клиническими рисками",
        "roleTest": "NO_ROLE",
        "question": "По какой шкале оценивается предотвратимость внутрибольничных осложнений?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "шкала SCORE"
            },
            {
                "id": 1,
                "answer": "шкала Ликерта"
            },
            {
                "id": 2,
                "answer": "шкала Глазго"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 11,
        "topic": "Глава 1. Стратегии обеспечения безопасности пациентов и управление клиническими рисками",
        "roleTest": "NO_ROLE",
        "question": "Как называется временный коллектив, создаваемый из сотрудников медицинской организации для разработки и внедрения программы повышения безопасности пациентов?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "Рабочая группа"
            },
            {
                "id": 1,
                "answer": "экспертный совет"
            },
            {
                "id": 2,
                "answer": "врачебная комиссия"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 12,
        "topic": "Глава 1. Стратегии обеспечения безопасности пациентов и управление клиническими рисками",
        "roleTest": "NO_ROLE",
        "question": "Для чего используется диаграмма Ганта?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "для иллюстрации плана, графика работ по какому-либо проекту"
            },
            {
                "id": 1,
                "answer": "для графического отображения статистических данных"
            },
            {
                "id": 2,
                "answer": "для расчета частотных показателей"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 13,
        "topic": "Глава 1. Стратегии обеспечения безопасности пациентов и управление клиническими рисками",
        "roleTest": "NO_ROLE",
        "question": "Для чего предназначена матрица ответственности?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "для наглядного отображения плана внедрения проекта"
            },
            {
                "id": 1,
                "answer": "для наглядного отображения ролей исполнителей и участников процесса"
            },
            {
                "id": 2,
                "answer": "для расчета уровня рисков, связанных с проектом"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 14,
        "topic": "Глава 1. Стратегии обеспечения безопасности пациентов и управление клиническими рисками",
        "roleTest": "NO_ROLE",
        "question": "В чем выражается прогностическая точность шкал оценки риска?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "в площади под ROC-кривой"
            },
            {
                "id": 1,
                "answer": "в чувствительности"
            },
            {
                "id": 2,
                "answer": "в специфичности"
            },
            {
                "id": 3,
                "answer": "в прогностической ценности отрицательного результата"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 15,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "DOCTOR_ROLE",
        "question": "Кто проводит первичную оценку риска падений при госпитализации пациента?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "медсестра приемного отделения"
            },
            {
                "id": 1,
                "answer": "врач приемного отделения"
            },
            {
                "id": 2,
                "answer": "лечащий врач или врач ОРИТ (при поступлении минуя приемное отделение)"
            },
            {
                "id": 3,
                "answer": "дежурный врач в выходные и праздничные дни"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 16,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "DOCTOR_ROLE",
        "question": "Кто проводит комплексную оценку риска падений пациента?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "старшая медсестра коечного отделения"
            },
            {
                "id": 1,
                "answer": "врач приемного отделения"
            },
            {
                "id": 2,
                "answer": "лечащий врач или врач ОРИТ (при поступлении минуя приемное отделение)"
            },
            {
                "id": 3,
                "answer": "дежурный врач в выходные и праздничные дни"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            2,
            3
        ]
    },
    {
        "id": 17,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "DOCTOR_ROLE",
        "question": "Где регистрируется результат комплексной оценки риска падений?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "в первичном осмотре/дневнике истории болезни в МИС"
            },
            {
                "id": 1,
                "answer": "в истории болезни в МИС"
            },
            {
                "id": 2,
                "answer": "на отдельном листе со шкалой, вкладываемом в бумажную историю болезни"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 18,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "DOCTOR_ROLE",
        "question": "В какие сроки регистрируется результат комплексной оценки риска падений?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "в первые 3 часа после госпитализации"
            },
            {
                "id": 1,
                "answer": "в первые 12 часов после госпитализации"
            },
            {
                "id": 2,
                "answer": "в первые сутки после госпитализации"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 19,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "DOCTOR_ROLE",
        "question": "К факторам риска падений, учитываемых при первичной оценке риска, относятся:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "падения в последние 3 месяца до госпитализации"
            },
            {
                "id": 1,
                "answer": "головокружения в анамнезе"
            },
            {
                "id": 2,
                "answer": "хирургические вмешательства в анамнезе"
            },
            {
                "id": 3,
                "answer": "нестабильная походка"
            },
            {
                "id": 4,
                "answer": "экстренная госпитализация"
            },
            {
                "id": 5,
                "answer": "мышечная слабость"
            },
            {
                "id": 6,
                "answer": "прием антикоагулянтов"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            3,
            5
        ]
    },
    {
        "id": 20,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "DOCTOR_ROLE",
        "question": "Каким категориям пациентов не проводят оценку риска падений?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "с параличом / полной иммобилизацией"
            },
            {
                "id": 1,
                "answer": "перенесшим несколько падений в предшествующие 6 месяцев"
            },
            {
                "id": 2,
                "answer": "с частично ограниченной подвижностью"
            },
            {
                "id": 3,
                "answer": "с нарушением сознания"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1
        ]
    },
    {
        "id": 21,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "DOCTOR_ROLE",
        "question": "Какие из следующих утверждений верны?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "маркировку пациента с высоким риском падения проводит медсестра приемного отделения с помощью красного браслета"
            },
            {
                "id": 1,
                "answer": "медсестра приемного отделения ставит штамп «Высокий риск падения» на титульном листе истории болезни"
            },
            {
                "id": 2,
                "answer": "лечащий врач назначает услугу по маркировке пациента с высоким риском падения"
            },
            {
                "id": 3,
                "answer": "медсестра приемного отделения самостоятельно оценивает необходимость маркировки пациента с высоким риском падений"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            1,
            2
        ]
    },
    {
        "id": 22,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "DOCTOR_ROLE",
        "question": "Какие из следующих утверждений верны?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "причина падения зачастую определяется соотношением риска пациента, условий окружающей обстановки и поведением пациента"
            },
            {
                "id": 1,
                "answer": "профилактика падений входит исключительно в ответственность медсестер"
            },
            {
                "id": 2,
                "answer": "идентификатор пациента (например, браслет) помогает выделять пациентов с высоким риском падения"
            },
            {
                "id": 3,
                "answer": "все шкалы риска падений имеют высокую прогностическую точность и не требуют дополнительного клинического суждения врача"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            2
        ]
    },
    {
        "id": 23,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "DOCTOR_ROLE",
        "question": "Какие из следующих утверждений являются ложными?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "риск падений необходимо переоценивать каждый день"
            },
            {
                "id": 1,
                "answer": "у пациента, принимающего психотропные препараты, повышен риск падения"
            },
            {
                "id": 2,
                "answer": "у пациента с остеопорозом высока вероятность получения серьезной травмы при падении"
            },
            {
                "id": 3,
                "answer": "пациенты с высоким риском падений могут посещать диагностические процедуры без сопровождающего"
            },
            {
                "id": 4,
                "answer": "пациентам необязательно знать о том, что у них высокий риск падения"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            3,
            4
        ]
    },
    {
        "id": 24,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "DOCTOR_ROLE",
        "question": "Какие из следующих утверждений верны?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "уход за пациентом с высоким риском падения является стандартной услугой, не требующей указания кратности наблюдения"
            },
            {
                "id": 1,
                "answer": "медсестра самостоятельно определяет кратность ухода за пациентом с высоким риском падения"
            },
            {
                "id": 2,
                "answer": "профилактические меры должны предприниматься только в отношении пациентов с высоким риском падений"
            },
            {
                "id": 3,
                "answer": "падение может быть вызвано разными причинами, поэтому профилактика падений должна быть комплексной"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    },
    {
        "id": 25,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "DOCTOR_ROLE",
        "question": "В каких случаях необходимо проводить переоценку риска падения?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "при переводе пациента в/из ОРИТ"
            },
            {
                "id": 1,
                "answer": "после развития кровотечения"
            },
            {
                "id": 2,
                "answer": "при назначении антибиотиков"
            },
            {
                "id": 3,
                "answer": "при развитии внутрибольничного инсульта"
            },
            {
                "id": 4,
                "answer": "при развитии инфекционного осложнения"
            },
            {
                "id": 5,
                "answer": "в первые 2 часа после операции"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            3
        ]
    },
    {
        "id": 26,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "DOCTOR_ROLE",
        "question": "Какие из следующих утверждений верны?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "лист наблюдения за пациентом с высоким риском падения заполняют совместно лечащий врач и медсестра"
            },
            {
                "id": 1,
                "answer": "лист наблюдения необходимо вести для пациентов с высоким и средним риском падений"
            },
            {
                "id": 2,
                "answer": "лист наблюдения за пациентом с высоким риском падения вкладывается в историю болезни"
            },
            {
                "id": 3,
                "answer": "лист наблюдения за пациентом с высоким риском падений ведет медсестра и хранит на посту отделения"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    },
    {
        "id": 27,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "DOCTOR_ROLE",
        "question": "Что из нижеперечисленного потенциально снижает риск падений пациента?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "блокировка колес мебели"
            },
            {
                "id": 1,
                "answer": "нескользящая обувь"
            },
            {
                "id": 2,
                "answer": "размещение часто используемых устройств (кнопка вызова, телефон, пульт дистанционного управления) в пределах досягаемости больного"
            },
            {
                "id": 3,
                "answer": "частый обход для уточнения потребностей пациента"
            },
            {
                "id": 4,
                "answer": "поднятые ограничители кровати"
            },
            {
                "id": 5,
                "answer": "занятия лечебной физкультурой с инструктором"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2,
            3,
            4,
            5
        ]
    },
    {
        "id": 28,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "DOCTOR_ROLE",
        "question": "Рапорт о падении пациента должен быть оформлен:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "в течение 12 часов после падения"
            },
            {
                "id": 1,
                "answer": "в течение 2 рабочих дней после падения"
            },
            {
                "id": 2,
                "answer": "в течение 2 недель после падения"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 29,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "DOCTOR_ROLE",
        "question": "Какие из следующих утверждений верны?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "рапорт о падении является частью истории болезни"
            },
            {
                "id": 1,
                "answer": "рапорт о падении предназначен для анализа безопасности пациентов стационара"
            },
            {
                "id": 2,
                "answer": "в рапорте о падении указывается факт документирования оценки риска падения в ИБ и предпринимавшиеся меры по профилактике падений"
            },
            {
                "id": 3,
                "answer": "рапорт о падении передается главному врачу для принятия административных мер"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            1,
            2
        ]
    },
    {
        "id": 30,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "NURSE_ROLE",
        "question": "Кто проводит первичную оценку риска падений при госпитализации пациента?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "медсестра приемного отделения"
            },
            {
                "id": 1,
                "answer": "врач приемного отделения"
            },
            {
                "id": 2,
                "answer": "лечащий врач или врач ОРИТ (при поступлении минуя приемное отделение)"
            },
            {
                "id": 3,
                "answer": "медсестра коечного отделения"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 31,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "NURSE_ROLE",
        "question": "Кто проводит комплексную оценку риска падений пациента?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "медсестра приемного отделения"
            },
            {
                "id": 1,
                "answer": "старшая медсестра коечного отделения"
            },
            {
                "id": 2,
                "answer": "дежурный врач в выходные и праздничные дни"
            },
            {
                "id": 3,
                "answer": "лечащий врач или врач ОРИТ (при поступлении минуя приемное отделение)"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            2,
            3
        ]
    },
    {
        "id": 32,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "NURSE_ROLE",
        "question": "Где регистрируется результат комплексной оценки риска падений?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "в первичном осмотре/дневнике истории болезни в МИС"
            },
            {
                "id": 1,
                "answer": "в истории болезни в МИС"
            },
            {
                "id": 2,
                "answer": "на отдельном листе со шкалой, вкладываемом в бумажную историю болезни"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 33,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "NURSE_ROLE",
        "question": "Какие из следующих утверждений верны?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "маркировку пациента с высоким риском падения проводит медсестра приемного отделения с помощью красного браслета"
            },
            {
                "id": 1,
                "answer": "для маркировки пациента с высоким риском падения используют желтый браслет"
            },
            {
                "id": 2,
                "answer": "лечащий врач назначает услугу по маркировке пациента с высоким риском падения"
            },
            {
                "id": 3,
                "answer": "медсестра коечного отделения самостоятельно оценивает необходимость маркировки пациента с высоким риском падений"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            1,
            2
        ]
    },
    {
        "id": 34,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "NURSE_ROLE",
        "question": "Какие из следующих утверждений верны?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "штамп «Высокий риск падения» ставит медсестра приемного отделения на титульном листе истории болезни"
            },
            {
                "id": 1,
                "answer": "медсестра коечного отделения ставит штамп «Высокий риск падения» на титульном листе ИБ при его отсутствии у пациентов высокого риска"
            },
            {
                "id": 2,
                "answer": "лечащий врач ставит штамп «Высокий риск падения» после первичного осмотра пациента"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1
        ]
    },
    {
        "id": 35,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "NURSE_ROLE",
        "question": "В какие сроки регистрируется результат комплексной оценки риска падений?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "в первые 3 часа после госпитализации"
            },
            {
                "id": 1,
                "answer": "в первые 12 часов после госпитализации"
            },
            {
                "id": 2,
                "answer": "в первые сутки после госпитализации"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 36,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "NURSE_ROLE",
        "question": "Какие из следующих утверждений верны?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "лист наблюдения за пациентом с высоким риском падения заполняют совместно лечащий врач и медсестра"
            },
            {
                "id": 1,
                "answer": "лист наблюдения необходимо вести для пациентов с высоким и средним риском падений"
            },
            {
                "id": 2,
                "answer": "лист наблюдения за пациентом с высоким риском падения вкладывается в историю болезни"
            },
            {
                "id": 3,
                "answer": "лист наблюдения за пациентом с высоким риском падений ведет медсестра и хранит на посту отделения"
            },
            {
                "id": 4,
                "answer": "листы наблюдения за пациентом с высоким риском падений старшая медсестра хранит в течение 3 месяцев"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            3,
            4
        ]
    },
    {
        "id": 37,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "NURSE_ROLE",
        "question": "Какие из следующих утверждений верны?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "причина падения зачастую определяется соотношением риска пациента, условий окружающей обстановки и поведением пациента"
            },
            {
                "id": 1,
                "answer": "профилактика падений входит исключительно в ответственность медсестер"
            },
            {
                "id": 2,
                "answer": "идентификатор пациента (например, браслет) помогает выделять пациентов с высоким риском падения"
            },
            {
                "id": 3,
                "answer": "пациенты с высоким риском падений могут посещать диагностические процедуры без сопровождающего"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            2
        ]
    },
    {
        "id": 38,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "NURSE_ROLE",
        "question": "Какие из следующих утверждений верны?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "уход за пациентом с высоким риском падения является стандартной услугой, не требующей указания кратности наблюдения"
            },
            {
                "id": 1,
                "answer": "медсестра самостоятельно определяет кратность ухода за пациентом с высоким риском падения"
            },
            {
                "id": 2,
                "answer": "профилактические меры должны предприниматься только в отношении пациентов с высоким риском падений"
            },
            {
                "id": 3,
                "answer": "падение может быть вызвано разными причинами, поэтому профилактика падений должна быть комплексной"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            3
        ]
    },
    {
        "id": 39,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "NURSE_ROLE",
        "question": "Какие категории пациентов имеют заведомо высокий риск падения?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "перенесшие диагностические или хирургические процедуры с применением анестезии или седации"
            },
            {
                "id": 1,
                "answer": "перенесшие несколько падений в предшествующие 6 месяцев"
            },
            {
                "id": 2,
                "answer": "с частично ограниченной подвижностью"
            },
            {
                "id": 3,
                "answer": "госпитализируемые в экстренном порядке"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1
        ]
    },
    {
        "id": 40,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "NURSE_ROLE",
        "question": "Какие из следующих утверждений являются ложными?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "риск падений необходимо переоценивать каждый день"
            },
            {
                "id": 1,
                "answer": "у пациента, принимающего психотропные препараты, повышен риск падения"
            },
            {
                "id": 2,
                "answer": "у пациента с остеопорозом высока вероятность получения серьезной травмы при падении"
            },
            {
                "id": 3,
                "answer": "постовая медсестра отмечает в журнале передачи дежурств пациентов с высоким риском падения"
            },
            {
                "id": 4,
                "answer": "пациентам необязательно знать о том, что у них высокий риск падения"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            4
        ]
    },
    {
        "id": 41,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "NURSE_ROLE",
        "question": "Какие из следующих утверждений верны?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "постовая медсестра отмечает в журнале передачи дежурств пациентов с высоким риском падения"
            },
            {
                "id": 1,
                "answer": "случаи обнаружения пациента с высоким риском падений без сопровождающего вне отделения подлежат регистрации в листе наблюдений"
            },
            {
                "id": 2,
                "answer": "система видеомониторинга может использоваться для контроля за пациентами с высоким риском падения"
            },
            {
                "id": 3,
                "answer": "после хирургической /диагностической/ процедуры сопровождающему пациента медработнику выдается сигнальная карточка с временем проведения анестезии / седации"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2,
            3
        ]
    },
    {
        "id": 42,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "NURSE_ROLE",
        "question": "Что из нижеперечисленного потенциально снижает риск падений пациента?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "блокировка колес мебели"
            },
            {
                "id": 1,
                "answer": "нескользящая обувь"
            },
            {
                "id": 2,
                "answer": "размещение часто используемых устройств (в том числе кнопка вызова, телефон и пульт дистанционного управления) в пределах досягаемости больного"
            },
            {
                "id": 3,
                "answer": "частый обход для уточнения потребностей пациента"
            },
            {
                "id": 4,
                "answer": "поднятые ограничители кровати"
            },
            {
                "id": 5,
                "answer": "занятия лечебной физкультурой с инструктором"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2,
            3,
            4,
            5
        ]
    },
    {
        "id": 43,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "NURSE_ROLE",
        "question": "Рапорт о падении пациента должен быть оформлен:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "в течение 12 часов после падения"
            },
            {
                "id": 1,
                "answer": "в течение 2 рабочих дней после падения"
            },
            {
                "id": 2,
                "answer": "в течение 2 недель после падения"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 44,
        "topic": "Глава 2. Протокол профилактики падений пациентов",
        "roleTest": "NURSE_ROLE",
        "question": "Какие из следующих утверждений верны?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "рапорт о падении является частью истории болезни"
            },
            {
                "id": 1,
                "answer": "рапорт о падении предназначен для анализа безопасности пациентов стационара"
            },
            {
                "id": 2,
                "answer": "в рапорте о падении указывается факт документирования оценки риска падения в ИБ и предпринимавшиеся меры по профилактике падений"
            },
            {
                "id": 3,
                "answer": "рапорт о падении передается главному врачу для принятия административных мер"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            1,
            2
        ]
    },
    {
        "id": 45,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "DOCTOR_ROLE",
        "question": "Какие показатели состояния кожи необходимо отразить в истории болезни при описании кожных покровов после первичного осмотра пациента?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "температура кожи"
            },
            {
                "id": 1,
                "answer": "цвет"
            },
            {
                "id": 2,
                "answer": "влажность"
            },
            {
                "id": 3,
                "answer": "наличие повреждений"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2,
            3
        ]
    },
    {
        "id": 46,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "DOCTOR_ROLE",
        "question": "Кто проводит оценку риска развития пролежней при госпитализации пациента?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "медсестра приемного отделения"
            },
            {
                "id": 1,
                "answer": "врач приемного отделения"
            },
            {
                "id": 2,
                "answer": "лечащий врач или врач ОРИТ (при поступлении минуя приемное отделение)"
            },
            {
                "id": 3,
                "answer": "палатная медсестра"
            },
            {
                "id": 4,
                "answer": "дежурный врач в выходные и праздничные дни"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 47,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "DOCTOR_ROLE",
        "question": "Какой метод применяется в МО для формальной оценки риска развития пролежней?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "шкала Брейдена"
            },
            {
                "id": 1,
                "answer": "шкала Ватерлоу"
            },
            {
                "id": 2,
                "answer": "шкала Нортона"
            },
            {
                "id": 3,
                "answer": "шкала Меддлей"
            },
            {
                "id": 4,
                "answer": "клиническое суждение врача"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 48,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "DOCTOR_ROLE",
        "question": "Какие градации применяются в МО для описания риска развития пролежней?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "высокий и низкий"
            },
            {
                "id": 1,
                "answer": "высокий, средний и низкий"
            },
            {
                "id": 2,
                "answer": "очень высокий, высокий, средний и низкий"
            },
            {
                "id": 3,
                "answer": "очень высокий, высокий, средний, низкий, нет риска"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 49,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "DOCTOR_ROLE",
        "question": "В какие сроки необходимо зарегистрировать в ИБ результаты оценки риска развития пролежней?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "в первые 3 часа после госпитализации"
            },
            {
                "id": 1,
                "answer": "в первые 12 часов после госпитализации"
            },
            {
                "id": 2,
                "answer": "в первые сутки после госпитализации"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 50,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "DOCTOR_ROLE",
        "question": "К факторам риска развития пролежней, учитываемых при формальной оценке риска, относятся:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "степень физической активности"
            },
            {
                "id": 1,
                "answer": "питание"
            },
            {
                "id": 2,
                "answer": "влажность кожи"
            },
            {
                "id": 3,
                "answer": "болевая чувствительность"
            },
            {
                "id": 4,
                "answer": "способность самостоятельно менять положение тела"
            },
            {
                "id": 5,
                "answer": "нарушение координации движений"
            },
            {
                "id": 6,
                "answer": "прием мочегонных средств"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2,
            3,
            4
        ]
    },
    {
        "id": 51,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "DOCTOR_ROLE",
        "question": "В каких случаях необходимо проводить переоценку риска развития пролежней?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "при переводе пациента в/из ОРИТ"
            },
            {
                "id": 1,
                "answer": "при изменении двигательного режима"
            },
            {
                "id": 2,
                "answer": "при развитии инфекционного осложнения"
            },
            {
                "id": 3,
                "answer": "при развитии недержания мочи или кала"
            },
            {
                "id": 4,
                "answer": "после операции, длившейся свыше 4 часов"
            },
            {
                "id": 5,
                "answer": "после операции, длившейся свыше 2 часов"
            },
            {
                "id": 6,
                "answer": "при дегидратации и развитии дефицита принимаемой пищи"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2,
            3,
            4,
            6
        ]
    },
    {
        "id": 52,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "DOCTOR_ROLE",
        "question": "Каким категориям пациентов не проводят формальную оценку риска развития пролежней?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "с параличом / полной иммобилизацией"
            },
            {
                "id": 1,
                "answer": "при наличии пролежней (из-за заведомо высокого риска)"
            },
            {
                "id": 2,
                "answer": "при планируемом сроке госпитализации меньше 24 часов"
            },
            {
                "id": 3,
                "answer": "при планируемом сроке госпитализации меньше 48 часов"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            1,
            2
        ]
    },
    {
        "id": 53,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "DOCTOR_ROLE",
        "question": "Какие из следующих утверждений верны?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "переоценка риска развития пролежней выполняется по тем же требованиям, что и первичная оценка"
            },
            {
                "id": 1,
                "answer": "переоценку риска развития пролежней необходимо проводить при выявлении нового пролежня"
            },
            {
                "id": 2,
                "answer": "переоценку риска развития пролежней необходимо проводить каждый день"
            },
            {
                "id": 3,
                "answer": "результат повторной оценки риска развития пролежней регистрируется в карте планирования результатов лечения ИБ, разделе «Оценка риска»"
            },
            {
                "id": 4,
                "answer": "врач информирует пациента и/или его родственников о высоком риске развития или о наличии пролежней и предоставляет им соответствующие информационные материалы"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            3,
            4
        ]
    },
    {
        "id": 54,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "DOCTOR_ROLE",
        "question": "Какие из следующих утверждений верны?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "карту профилактики пролежней медсестры ведут для пациентов с высоким риском развития пролежней"
            },
            {
                "id": 1,
                "answer": "карту профилактики пролежней медсестры ведут для пациентов со средним и высоким риском развития пролежней"
            },
            {
                "id": 2,
                "answer": "медсестра ведет карту профилактики пролежней для пациентов с пролежнями"
            },
            {
                "id": 3,
                "answer": "карту профилактики пролежней медсестры ведут для всех пациентов"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 55,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "DOCTOR_ROLE",
        "question": "С какой частотой проводится повторная оценка риска у пациентов с высоким риском развития пролежней?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "ежедневно"
            },
            {
                "id": 1,
                "answer": "каждые 7 дней"
            },
            {
                "id": 2,
                "answer": "однократно при госпитализации"
            },
            {
                "id": 3,
                "answer": "каждые 3 дня"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 56,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "DOCTOR_ROLE",
        "question": "Какие из следующих утверждений верны?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "для пациентов с высоким риском развития пролежней результаты периодической оценки риска врач регистрирует в бумажной форме, которая вкладывается в ИБ."
            },
            {
                "id": 1,
                "answer": "врач еженедельно документирует результат повторной оценки риска развития пролежней в МИС, в разделе «Оценка риска» карты планирования результатов лечения"
            },
            {
                "id": 2,
                "answer": "врач документирует результат переоценки риска развития пролежней в МИС, в разделе «Оценка риска» карты планирования результатов лечения, если категория риска изменилась"
            },
            {
                "id": 3,
                "answer": "профилактические меры должны предприниматься только в отношении пациентов с высоким риском развития пролежней"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1
        ]
    },
    {
        "id": 57,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "DOCTOR_ROLE",
        "question": "Какие из следующих утверждений верны?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "карту ухода за пациентом с пролежнями заполняют совместно врачи и медсестры"
            },
            {
                "id": 1,
                "answer": "карту ухода за пациентом с пролежнями ведут медсестры"
            },
            {
                "id": 2,
                "answer": "в карте ухода за пациентом с пролежнями регистрируют только пролежни III-IV стадии"
            },
            {
                "id": 3,
                "answer": "размеры пролежней необходимо оценивать и регистрировать в карте ухода за пациентом с пролежнями ежедневно"
            },
            {
                "id": 4,
                "answer": "размеры пролежней достаточно оценивать и регистрировать в карте ухода за пациентом с пролежнями 1 раз в неделю"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            1,
            4
        ]
    },
    {
        "id": 58,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "DOCTOR_ROLE",
        "question": "Обязательные компоненты ухода за пациентом с риском развития пролежней включают:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "уменьшение давления на костные ткани"
            },
            {
                "id": 1,
                "answer": "предупреждение трения и сдвига тканей во время перемещения пациента"
            },
            {
                "id": 2,
                "answer": "наблюдение за кожей над костными выступами"
            },
            {
                "id": 3,
                "answer": "поддержание чистоты кожи и ее умеренной влажности"
            },
            {
                "id": 4,
                "answer": "обеспечение пациента адекватным питанием и питьем"
            },
            {
                "id": 5,
                "answer": "ежедневная дезинфекция кожи"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2,
            3,
            4
        ]
    },
    {
        "id": 59,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "NURSE_ROLE",
        "question": "Кто проводит оценку риска развития пролежней при госпитализации пациента?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "медсестра приемного отделения"
            },
            {
                "id": 1,
                "answer": "врач приемного отделения"
            },
            {
                "id": 2,
                "answer": "лечащий врач или врач ОРИТ (при поступлении минуя приемное отделение)"
            },
            {
                "id": 3,
                "answer": "палатная медсестра"
            },
            {
                "id": 4,
                "answer": "дежурный врач в выходные и праздничные дни"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            2
        ]
    },
    {
        "id": 60,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "NURSE_ROLE",
        "question": "Какой метод применяется в МО для формальной оценки риска развития пролежней?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "шкала Брейдена"
            },
            {
                "id": 1,
                "answer": "шкала Ватерлоу"
            },
            {
                "id": 2,
                "answer": "шкала Нортона"
            },
            {
                "id": 3,
                "answer": "шкала Меддлей"
            },
            {
                "id": 4,
                "answer": "клиническое суждение врача"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 61,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "NURSE_ROLE",
        "question": "Какие градации применяются в МО для описания риска развития пролежней?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "высокий и низкий"
            },
            {
                "id": 1,
                "answer": "высокий, средний и низкий"
            },
            {
                "id": 2,
                "answer": "очень высокий, высокий, средний и низкий"
            },
            {
                "id": 3,
                "answer": "очень высокий, высокий, средний, низкий, нет риска"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 62,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "NURSE_ROLE",
        "question": "К факторам риска развития пролежней, учитываемых при формальной оценке риска, относятся:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "степень физической активности"
            },
            {
                "id": 1,
                "answer": "питание"
            },
            {
                "id": 2,
                "answer": "влажность кожи"
            },
            {
                "id": 3,
                "answer": "болевая чувствительность"
            },
            {
                "id": 4,
                "answer": "способность самостоятельно менять положение тела"
            },
            {
                "id": 5,
                "answer": "нарушение координации движений"
            },
            {
                "id": 6,
                "answer": "прием мочегонных средств"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2,
            3,
            4
        ]
    },
    {
        "id": 63,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "NURSE_ROLE",
        "question": "В каких случаях необходимо проводить переоценку риска развития пролежней?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "при переводе пациента в/из ОРИТ"
            },
            {
                "id": 1,
                "answer": "при изменении двигательного режима"
            },
            {
                "id": 2,
                "answer": "при развитии инфекционного осложнения"
            },
            {
                "id": 3,
                "answer": "при развитии недержания мочи или кала"
            },
            {
                "id": 4,
                "answer": "после операции, длившейся свыше 4 часов"
            },
            {
                "id": 5,
                "answer": "после операции, длившейся свыше 2 часов"
            },
            {
                "id": 6,
                "answer": "при дегидратации и развитии дефицита принимаемой пищи"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2,
            3,
            4,
            6
        ]
    },
    {
        "id": 64,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "NURSE_ROLE",
        "question": "Какие из следующих утверждений верны?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "переоценка риска развития пролежней выполняется по тем же требованиям, что и первичная оценка"
            },
            {
                "id": 1,
                "answer": "переоценку риска развития пролежней необходимо проводить при выявлении нового пролежня"
            },
            {
                "id": 2,
                "answer": "переоценку риска развития пролежней необходимо проводить каждый день"
            },
            {
                "id": 3,
                "answer": "результат повторной оценки риска развития пролежней регистрируется в карте планирования результатов лечения ИБ, разделе «Оценка риска»"
            },
            {
                "id": 4,
                "answer": "врач информирует пациента и/или его родственников о высоком риске развития или о наличии пролежней и предоставляет им соответствующие информационные материалы"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            3,
            4
        ]
    },
    {
        "id": 65,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "NURSE_ROLE",
        "question": "Какие из следующих утверждений верны?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "карту профилактики пролежней медсестры ведут для пациентов с высоким риском развития пролежней"
            },
            {
                "id": 1,
                "answer": "карту профилактики пролежней медсестры ведут для пациентов со средним и высоким риском развития пролежней"
            },
            {
                "id": 2,
                "answer": "медсестра ведет карту профилактики пролежней для пациентов с пролежнями"
            },
            {
                "id": 3,
                "answer": "карту профилактики пролежней медсестры ведут для всех пациентов"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            0
        ]
    },
    {
        "id": 66,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "NURSE_ROLE",
        "question": "С какой частотой проводится повторная оценка риска у пациентов с высоким риском развития пролежней?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "ежедневно"
            },
            {
                "id": 1,
                "answer": "каждые 7 дней"
            },
            {
                "id": 2,
                "answer": "однократно при госпитализации"
            },
            {
                "id": 3,
                "answer": "каждые 3 дня"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 67,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "NURSE_ROLE",
        "question": "Какие из следующих утверждений верны?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "для пациентов с высоким риском развития пролежней результаты периодической оценки риска врач регистрирует в бумажной форме, которая вкладывается в ИБ."
            },
            {
                "id": 1,
                "answer": "врач еженедельно документирует результат повторной оценки риска развития пролежней в МИС, в разделе «Оценка риска» карты планирования результатов лечения"
            },
            {
                "id": 2,
                "answer": "врач документирует результат переоценки риска развития пролежней в МИС, в разделе «Оценка риска» карты планирования результатов лечения, если категория риска изменилась"
            },
            {
                "id": 3,
                "answer": "профилактические меры должны предприниматься только в отношении пациентов с высоким риском развития пролежней"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1
        ]
    },
    {
        "id": 68,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "NURSE_ROLE",
        "question": "Какие из следующих утверждений верны?",
        "responseOptions": [
            {
                "id": 0,
                "answer": "карту ухода за пациентом с пролежнями заполняют совместно врачи и медсестры"
            },
            {
                "id": 1,
                "answer": "карту ухода за пациентом с пролежнями ведут медсестры"
            },
            {
                "id": 2,
                "answer": "в карте ухода за пациентом с пролежнями регистрируют только пролежни III-IV стадии"
            },
            {
                "id": 3,
                "answer": "размеры пролежней необходимо оценивать и регистрировать в карте ухода за пациентом с пролежнями ежедневно"
            },
            {
                "id": 4,
                "answer": "размеры пролежней достаточно оценивать и регистрировать в карте ухода за пациентом с пролежнями 1 раз в неделю"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            1,
            4
        ]
    },
    {
        "id": 69,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "NURSE_ROLE",
        "question": "Обязательные компоненты ухода за пациентом с риском развития пролежней включают:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "уменьшение давления на костные ткани"
            },
            {
                "id": 1,
                "answer": "предупреждение трения и сдвига тканей во время перемещения пациента"
            },
            {
                "id": 2,
                "answer": "наблюдение за кожей над костными выступами"
            },
            {
                "id": 3,
                "answer": "поддержание чистоты кожи и ее умеренной влажности"
            },
            {
                "id": 4,
                "answer": "обеспечение пациента адекватным питанием и питьем"
            },
            {
                "id": 5,
                "answer": "ежедневная дезинфекция кожи"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2,
            3,
            4
        ]
    },
    {
        "id": 70,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "NURSE_ROLE",
        "question": "Первым признаком развития пролежней I стации является:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "побледнение кожи после нажатия"
            },
            {
                "id": 1,
                "answer": "покраснение кожи, сохраняющееся после нажатия"
            },
            {
                "id": 2,
                "answer": "образование пузырей"
            },
            {
                "id": 3,
                "answer": "уплотнение кожи"
            },
            {
                "id": 4,
                "answer": "некроз кожи"
            }
        ],
        "isSingleResponce": true,
        "idCorrectAnswer": [
            1
        ]
    },
    {
        "id": 71,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "NURSE_ROLE",
        "question": "«Негативные технологии» очищения кожи в области пролежней 1-2 стадии включают:",
        "responseOptions": [
            {
                "id": 0,
                "answer": "обработку раствором перманганата калия"
            },
            {
                "id": 1,
                "answer": "обработку раствором перекиси водорода"
            },
            {
                "id": 2,
                "answer": "обработку камфорным спиртом"
            },
            {
                "id": 3,
                "answer": "обработку физиологическим раствором"
            },
            {
                "id": 4,
                "answer": "обработку раствором хлоргексидина"
            },
            {
                "id": 5,
                "answer": "обработку раствором бриллиантового зеленого"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2,
            4,
            5
        ]
    },
    {
        "id": 72,
        "topic": "Глава 3. Протокол профилактики пролежней",
        "roleTest": "NURSE_ROLE",
        "question": "Укажите типичную локализацию пролежней в положении пациента на спине",
        "responseOptions": [
            {
                "id": 0,
                "answer": "крестец"
            },
            {
                "id": 1,
                "answer": "лопатки"
            },
            {
                "id": 2,
                "answer": "пятки"
            },
            {
                "id": 3,
                "answer": "живот"
            },
            {
                "id": 4,
                "answer": "грудь"
            }
        ],
        "isSingleResponce": false,
        "idCorrectAnswer": [
            0,
            1,
            2
        ]
    }
]